import React, { createContext, useContext, useEffect } from 'react';

interface AuthContextType {
  isLoggedIn: boolean;
}

export const AuthContext = createContext<AuthContextType>(null!);

export function useAuth(): AuthContextType {
  return useContext(AuthContext);
}
