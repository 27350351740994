import React, { forwardRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { logout } from '../../../store/auth/thunk';
import { AppDispatch } from '../../../store';
import { useThemeToggler } from '../../../context/ThemeContext';

import { PersonalCabinet } from '../PersonalCabinet/PersonalCabinet';
import User from '../../../components/UsersList/User/User';
import Modal from '../../../components/Modal/Modal';
import Balance from '../../../components/Balance/Balance';

import { ReactComponent as IconBalance } from '../../../media/icons/balance.svg';
import { ReactComponent as IconBonus } from '../../../media/icons/bonus.svg';

import './AccountPopup.scss';
import { useMedia } from '../../../hooks/useMedia';
import { clearAuthData } from '../../../store/auth/store';
import { clearAllUserData } from '../../../store/user/store';
import { clearBillingData } from '../../../store/billing/store';
import { clearAllProjectsData } from '../../../store/projects/store';
import { clearTaskData } from '../../../store/tasks/store';
import {
  fetchBalanceStatistic,
  fetchBalanceTransactions,
  fetchBonusesStatistic,
  fetchBonusesTransactions,
} from '../../../store/billing/thunk';
import { PROJECT_ROLES_COLORS } from '../../../common/constant';
import { checkPermission } from '../../../hooks/checkPermission';

export const handleSignOut = (dispatch: any, navigate: any): void => {
  dispatch(logout())
    .unwrap()
    .then(() => {
      dispatch(clearAllUserData());
      dispatch(clearAuthData());
      dispatch(clearBillingData());
      dispatch(clearAllProjectsData());
      dispatch(clearTaskData());
      navigate('/login/');
    });
};

const AccountPopup = ({
  showModalBonuses,
  showModalBalance,
  balance,
  bonuses,
}: any): JSX.Element => {
  const [isCabinetShow, setIsCabinetShow] = useState<{
    state: boolean;
    index: number;
  }>({
    state: false,
    index: 0,
  });
  const { isMobile } = useMedia();

  return (
    <>
      <Modal
        title="Особистий кабінет"
        isActive={isCabinetShow.state}
        size="sm"
        handleClose={() => setIsCabinetShow({ ...isCabinetShow, state: false })}
        leftButton={false}
        headerModalClassName="settings-panel__modal-header"
        modalClassName="personal-cabinet__modal"
        bodyClassName="personal-cabinet__modal-body"
      >
        <PersonalCabinet defaultIndex={isCabinetShow.index} />
      </Modal>
      {!isMobile ? (
        <AccountPopupInner setIsCabinetShow={setIsCabinetShow} />
      ) : (
        <Modal
          title="Акаунт"
          isActive={isMobile}
          size="sm"
          handleClose={() =>
            setIsCabinetShow({ ...isCabinetShow, state: false })
          }
          leftButton={false}
          headerModalClassName="settings-panel__modal-header"
          modalClassName="personal-cabinet__modal"
        >
          <AccountPopupInner
            setIsCabinetShow={setIsCabinetShow}
            isMobile={isMobile}
            showModalBonuses={showModalBonuses}
            showModalBalance={showModalBalance}
            balance={balance}
            bonuses={bonuses}
          />
        </Modal>
      )}
    </>
  );
};

const AccountPopupInner = forwardRef(
  (
    {
      setIsCabinetShow,
      isMobile,
      showModalBonuses,
      showModalBalance,
      balance,
      bonuses,
    }: any,
    ref: any
  ): JSX.Element => {
    const { theme } = useThemeToggler();
    const { userInfo } = useSelector((state: any) => state.userData);
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const { balanceTransactions } = useSelector(
      (state: any) => state.billingData
    );

    return (
      <ul
        ref={ref}
        className={`list account-popup account-popup--${theme} ${
          isMobile && 'account-popup--mobile'
        }`}
      >
        <li className="account-popup__item">
          <User
            user={userInfo}
            size="md"
            backgroundColor={
              userInfo.statusId === 1
                ? PROJECT_ROLES_COLORS[userInfo.roleId]
                : '#C1C1C1'
            }
          />
          <div className="account-popup__container">
            <p className="account-popup__name">
              {checkPermission('CLIENT') || checkPermission('MANAGER')
                ? `${userInfo.firstName} ${userInfo.lastName}`
                : `${userInfo.nicFirstname} ${userInfo.nicLastname}`}
            </p>
            <p className="account-popup__email">{userInfo.email}</p>
          </div>
          {isMobile && (
            <div
              style={{
                flexBasis: '100%',
                marginTop: '22px',
                display: 'flex',
                columnGap: '19px',
              }}
            >
              <div
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  showModalBalance(true);
                  dispatch(
                    fetchBalanceTransactions({
                      listType: 'all',
                      lastId: balanceTransactions?.lastId,
                    })
                  );
                  dispatch(fetchBalanceStatistic());
                  showModalBalance(true);
                }}
              >
                <Balance money={balance}>
                  <IconBalance />
                </Balance>
              </div>
              {checkPermission('CLIENT') && (
                <div
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    showModalBonuses(true);
                    dispatch(fetchBonusesStatistic());
                    dispatch(fetchBonusesTransactions());
                    showModalBonuses(true);
                  }}
                >
                  <Balance money={bonuses}>
                    <IconBonus />
                  </Balance>
                </div>
              )}
            </div>
          )}
        </li>
        <li className="account-popup__item">
          <button
            type="button"
            className="account-popup__btn"
            onClick={() => {
              setIsCabinetShow({ state: true, index: 0 });
            }}
          >
            <span className="ci ci-user_circle" />
            Профіль
          </button>
        </li>
        <li className="account-popup__item">
          <button
            type="button"
            className="account-popup__btn"
            onClick={() => setIsCabinetShow({ state: true, index: 1 })}
          >
            <span className="ci ci-settings" />
            Налаштування
          </button>
        </li>
        <li className="account-popup__item">
          <button
            type="button"
            className="account-popup__btn"
            onClick={() => handleSignOut(dispatch, navigate) as any}
          >
            <span className="ci ci-exit" />
            Вихід
          </button>
        </li>
      </ul>
    );
  }
);

export default AccountPopup;
