import React, {
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  useMemo,
  useCallback,
  forwardRef,
} from 'react';
import Tooltip from 'rc-tooltip';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';

import { useThemeToggler } from '../../context/ThemeContext';
import { checkPermission } from '../../hooks/checkPermission';

import { TaskItem } from './types';
import { TUserData } from '../ProjectList/types';
import { IProjectData } from '../../common/types';

import TaskCard from './TaskCard/TaskCard';
import CreateTask from '../../forms/CreateTask/CreateTask';
import {
  PROJECT_ROLES,
  STAGES_DATA,
  STAGES_DATA_ALTERNATE,
  STAGES_DATA_COLUMNS_PM_STAFF,
  STAGES_DATA_COLUMNS_SM_CLIENT,
  STATUS_DRAFT,
  STATUS_NEW,
  generalChatType,
} from '../../common/constant';
import {
  getProjectData,
  getSingleTask,
  getStaffTasks,
  getUsersList,
} from '../../store/projects/thunk';
import { AppDispatch } from '../../store';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import Spinner from '../../components/UI/Spinner/Spinner';
import { setCurrentProjectRoleId } from '../../store/user/store';
import { useSocketListener } from '../../hooks/useSocketListener';
import { getFilesList } from '../../store/tasks/thunk';

import './TaskList.scss';
import { clearProjectInfo, updateOneTask } from '../../store/projects/store';
import { getRoleList } from '../../store/user/thunk';
/* eslint-disable */

const TaskList = (): JSX.Element => {
  const labelInputRef = useRef<HTMLInputElement>(null);
  const columnsRef = useRef<any>([]);
  const [isCreateTaskForm, toggleCreateTaskForm] = useState<boolean>(false);
  const { slugTask, slug } = useParams();
  const { pathname, state } = useLocation();
  const [isPreviewCreateForm, togglePreviewCreateForm] =
    useState<boolean>(true);
  const { theme } = useThemeToggler();
  const { taskInfo } = useSelector(
    (state: { taskData: any }) => state.taskData
  );
  const { projectInfo } = useSelector(
    (state: { projectData: IProjectData }) => state.projectData
  );
  const { userInfo } = useSelector(
    (state: { userData: any }) => state.userData
  );
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [draftTasksVisible, setDraftTasksVisible] = useState<any>({
    isVisible: false,
    once: false,
  });
  const ref = useRef<HTMLLIElement>(null);
  const addTaskButtonRef = useRef<HTMLButtonElement>(null);

  useOnClickOutside(addTaskButtonRef, (e: any) => {
    const createTaskInput = document.querySelector(
      '.form__create-task-input'
    ) as any;
    if (!createTaskInput) return;
    if (
      addTaskButtonRef?.current?.contains(e.target) ||
      ref?.current?.contains(e.target) ||
      createTaskInput?.value.trim().length > 0
    )
      return;

    toggleCreateTaskForm(false);
    togglePreviewCreateForm(true);
  });

  const projectTaskEventHandlerSocket = useCallback(
    (data: any) => {
      if (data.pSlug === slug) {
        dispatch(getProjectData(data.pSlug as string));
      }
    },
    [slug]
  );

  const taskFileEventHandler = useCallback(
    (data: any) => {
      if (data.slug === slug) {
        dispatch(getFilesList({ typeId: data.type, slug: data.slug }));
        dispatch(getProjectData(slug as string));
      }
    },
    [slugTask, taskInfo]
  );

  useEffect(() => {
    if (
      (checkPermission('STAFF') || checkPermission('MANAGER')) &&
      !projectInfo?.stageList?.length
    ) {
      const getStringParams = sessionStorage
        .getItem('filters-params')
        ?.toString();
      dispatch(getStaffTasks(getStringParams));
    } else if (checkPermission('CLIENT')) {
      if (!slug) {
        return;
      }
      dispatch(getProjectData(slug as string));
    }
  }, [
    // projectInfo?.stageList?.length,
    userInfo?.avatar?.name,
    userInfo?.avatar?.url,
    userInfo?.firstName,
    userInfo?.lastName,
    userInfo?.email,
    slug,
  ]);

  useSocketListener('projectEvent', projectTaskEventHandlerSocket);
  useSocketListener('taskFile', taskFileEventHandler);

  // here is for clearing project info between projects (only for client`s role)
  useLayoutEffect(() => {
    if (checkPermission('CLIENT')) {
      return () => {
        dispatch(clearProjectInfo());
      };
    }
  }, []);

  useLayoutEffect(() => {
    if (slug) {
      dispatch(getProjectData(slug))
        .unwrap()
        .then(() => {
          dispatch(getUsersList(slug as string))
            .unwrap()
            .then((e: any) => {
              if (!userInfo?.id) return;

              dispatch(
                setCurrentProjectRoleId(
                  e?.find((user: TUserData) => user.id === userInfo?.id)?.roleId
                )
              );
            });
        })
        .catch(() => {
          navigate('/');
        });
    }
  }, [slug, userInfo?.id]);

  useEffect(() => {
    const main = document.querySelector('.main');
    const mainTasksClassName = 'main--tasks';
    const isProjectPage = pathname.includes('project');

    if (isProjectPage && !main?.classList.contains(mainTasksClassName)) {
      main?.classList.add(mainTasksClassName);
    }

    return () => {
      main?.classList.remove(mainTasksClassName);
    };
  }, [slug, slugTask]);

  useEffect(() => {
    for (let i = 0, l = columnsRef.current?.length; i < l; i++) {
      const isScroll =
        columnsRef.current[i]?.scrollHeight -
        columnsRef.current[i]?.clientHeight;
      if (isScroll) {
        columnsRef.current[i]?.classList.add('list--scroll--active');
      } else {
        columnsRef.current[i]?.classList.remove('list--scroll--active');
      }
    }
  }, [projectInfo, slugTask, slug, draftTasksVisible.isVisible]);

  useEffect(() => {
    if (isCreateTaskForm || !isPreviewCreateForm) {
      labelInputRef.current?.click();
    }
  }, [isCreateTaskForm, isPreviewCreateForm]);

  useEffect(() => {
    toggleCreateTaskForm(false);
  }, [slugTask]);

  // useEffect(() => {
  //   if (checkPermission('STAFF') || checkPermission('MANAGER')) {
  //     dispatch(getStaffTasks());
  //   } else if (checkPermission('CLIENT')) {
  //     dispatch(getProjectData(slug as string));
  //   }
  // }, [
  //   userInfo?.avatar?.name,
  //   userInfo?.avatar?.url,
  //   userInfo?.firstName,
  //   userInfo?.lastName,
  //   userInfo?.email,
  //   slug,
  // ]);

  useSocketListener('user', () => {
    if (checkPermission('STAFF') || checkPermission('MANAGER')) {
      const getStringParams = sessionStorage
        .getItem('filters-params')
        ?.toString();
      dispatch(getStaffTasks(getStringParams));
    } else if (checkPermission('CLIENT')) {
      dispatch(getProjectData(slug as string));
    }
  });

  const handlePreviewCreateForm = (): void => {
    togglePreviewCreateForm(false);
  };

  const projectTaskEventHandler = useCallback(
    (data: any) => {
      if (slugTask && slug === data.pSlug) {
        dispatch(getProjectData(data.pSlug as string));
      } else if (slug) {
        dispatch(getProjectData(slug as string));
      } else if (checkPermission('STAFF')) {
        // dispatch(getSingleTask(data.slug));
        dispatch(getStaffTasks());
      }
    },
    [slugTask]
  );

  const handleChatSocket = useCallback(
    (data: any): void => {
      // if (!navigator.onLine) {
      //   return;
      // }
      // if (data.type === generalChatType && slug) {
      //   dispatch(getProjectData(slug as string));
      // } else if (taskInfo?.project?.slug) {
      //   if (checkPermission('CLIENT')) {
      //     dispatch(getProjectData(taskInfo.project.slug as string));
      //   } else if (checkPermission('STAFF') || checkPermission('MANAGER')) {
      //     const getStringParams = sessionStorage
      //       .getItem('filters-params')
      //       ?.toString();
      //     dispatch(getStaffTasks(getStringParams));
      //   }
      // }
      // here is some logic for updating task info after chat message.needed to ask about it
    },
    [slug, taskInfo?.project?.slug]
  );

  const actualColumnsByUserType =
    checkPermission('CLIENT') || userInfo?.roleId === 7
      ? STAGES_DATA_COLUMNS_SM_CLIENT
      : STAGES_DATA_COLUMNS_PM_STAFF;

  const taskEventHandler = useCallback(
    (data: any) => {
      const { slug, pSlug } = data;
      dispatch(getSingleTask({ slug }))
        .unwrap()
        .then((taskData) => {
          const { id, taskBefore, stage } = taskData;
          const { stageList } = projectInfo;

          if (!stageList?.length && isClient) return;

          const indexOfColumnWhereTaskCardIsLocatedNow = (
            actualColumnsByUserType as any
          )[stage];

          // if filters are applied that task will not be updated
          if (
            sessionStorage
              .getItem('filters-params')
              ?.toString()
              ?.includes('filterProjectSlug') &&
            !sessionStorage
              .getItem('filters-params')
              ?.toString()
              ?.includes(pSlug) &&
            isManager
          ) {
            return;
          }
          dispatch(
            updateOneTask({
              id,
              taskData,
              taskBefore,
              stage,
              slug,
            })
          );
          // }
        })
        .catch((el) => console.log(el));
      // if (checkPermission('STAFF') || checkPermission('MANAGER')) {
      //   const getStringParams = sessionStorage
      //     .getItem('filters-params')
      //     ?.toString();
      //   dispatch(getStaffTasks(getStringParams));
      // }
    },
    [slug, projectInfo?.stageList, taskInfo?.statusId]
  );
  // const taskEventHandler = useCallback(
  //   (data: any) => {
  //     const { changeStatus } = data;
  //     if (
  //       !changeStatus &&
  //       (!(taskInfo?.statusId === STATUS_NEW && userInfo?.roleId === 7) ||
  //         !(
  //           (taskInfo?.statusId === STATUS_NEW ||
  //             taskInfo?.statusId === STATUS_DRAFT) &&
  //           userInfo?.roleId === 8
  //         ))
  //     ) {
  //       dispatch(getSingleTask(data.slug))
  //         .unwrap()
  //         .then((taskData) => {
  //           const { id } = taskData;
  //           const { stageList } = projectInfo;
  //           if (!stageList) return;
  //           const columnsAmount = stageList.length;
  //           const actualStageIndex = stageList
  //             ?.map((item: any) =>
  //               item?.taskList?.findIndex((task: any) => task.id === id)
  //             )
  //             .findIndex((item: any) => item !== -1);
  //           console.log('actualStageIndex', actualStageIndex);
  //           if (actualStageIndex < columnsAmount && actualStageIndex !== -1) {
  //             const index = stageList[actualStageIndex]?.taskList?.findIndex(
  //               (item: any) => item.id === id
  //             );
  //             if (index !== -1) {
  //               dispatch(
  //                 updateOneTask({
  //                   actualStageIndex,
  //                   index,
  //                   taskData,
  //                 })
  //               );
  //             }
  //           } else {
  //             if (checkPermission('STAFF')) {
  //               dispatch(getStaffTasks());
  //             } else if (checkPermission('CLIENT')) {
  //               dispatch(getProjectData(slug || data.pSlug));
  //             }
  //           }
  //         });
  //     } else {
  //       if (checkPermission('STAFF') || checkPermission('MANAGER')) {
  //         const getStringParams = sessionStorage
  //           .getItem('filters-params')
  //           ?.toString();
  //         dispatch(getStaffTasks(getStringParams));
  //       } else if (checkPermission('CLIENT')) {
  //         dispatch(getProjectData(slug || data.pSlug));
  //       }
  //     }
  //   },
  //   [slug, projectInfo?.stageList?.length, taskInfo?.statusId]
  // );

  // useSocketListener('projectTaskEvent', projectTaskEventHandler);
  useSocketListener('taskChat', handleChatSocket);
  useSocketListener('taskEvent', taskEventHandler);

  const renderContentCard = (amountElements: boolean): JSX.Element => {
    if (amountElements && isPreviewCreateForm) {
      return (
        <button
          type="button"
          className="task-list__container"
          onClick={handlePreviewCreateForm}
        >
          <span className="task-card__headline task-card__headline--custom">
            Створити нову задачу
          </span>
        </button>
      );
    }

    return (
      <CreateTask
        ref={labelInputRef}
        hideCreateTaskForm={() => {
          toggleCreateTaskForm(false);
          togglePreviewCreateForm(true);
        }}
        slug={slug}
        projectId={projectInfo.id}
      />
    );
  };

  const isInputHaveValueCondition = (): string | boolean => {
    if (labelInputRef.current) {
      return (
        labelInputRef.current!.nextElementSibling as HTMLInputElement
      ).value.trim();
    } else return false;
  };

  const renderCustomCard = (amountElements: boolean): JSX.Element | null =>
    isCreateTaskForm || amountElements ? (
      <div ref={ref as any} className="task-list__content">
        {renderContentCard(amountElements)}
      </div>
    ) : null;

  const hastasks = useMemo(() => {
    if (projectInfo?.stageList) {
      return projectInfo?.stageList?.some(
        (item: TaskItem) => item?.taskList?.length > 0
      );
    }
  }, [projectInfo?.stageList]);

  useEffect(() => {
    const container: any = document.querySelector('.main');

    if (!container) return;

    let isDown = false;
    let startX: number;
    let scrollLeft: number;

    const mouseDown = (e: MouseEvent) => {
      if (
        !(e.target as any).closest('.list') ||
        (e.target as any).closest('.list__item')
      )
        return;
      isDown = true;
      startX = e.pageX;
      scrollLeft = container.scrollLeft;
      (container as any).style.cursor = 'grabbing';
      (container as any).style.userSelect = 'none';
    };

    const mouseLeave = () => {
      isDown = false;
      (container as any).style.cursor = 'auto';
      (container as any).style.userSelect = 'auto';
    };

    const mouseUp = () => {
      isDown = false;
      (container as any).style.cursor = 'auto';
      (container as any).style.userSelect = 'auto';
    };

    const mouseMove = (e: MouseEvent) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - container.getBoundingClientRect().left;
      const speed = 1;
      const walk = (x - startX) * speed;
      container.scrollLeft = scrollLeft - walk;
    };

    document.addEventListener('mousedown', mouseDown);
    document.addEventListener('mouseleave', mouseLeave);
    document.addEventListener('mouseup', mouseUp);
    document.addEventListener('mousemove', mouseMove);

    return () => {
      document.removeEventListener('mousedown', mouseDown);
      document.removeEventListener('mouseleave', mouseLeave);
      document.removeEventListener('mouseup', mouseUp);
      document.removeEventListener('mousemove', mouseMove);
    };
  }, [slugTask, slug]);

  const keyFromUserData = `${userInfo?.avatar?.name}${userInfo?.avatar?.url}${userInfo?.firstName}${userInfo?.lastName}${userInfo?.email}`;

  const isManager = checkPermission('MANAGER');
  const isStaffOrPM = checkPermission('STAFF') || isManager;
  const isClient = checkPermission('CLIENT');

  return (
    <div className="list-wrapper">
      <div className="list-wrapper__content">
        {!Object.keys(projectInfo).length && (
          <div className="screen-loader-wrapper">
            <Spinner size="lg" className="task-loading" />
          </div>
        )}
        <Tasks
          key={keyFromUserData}
          theme={theme}
          ref={columnsRef}
          isStaffOrPM={isStaffOrPM}
          list={projectInfo.stageList}
          hastasks={hastasks}
          buttonRef={addTaskButtonRef}
          onClick={() => {
            toggleCreateTaskForm(true);
            togglePreviewCreateForm(false);
          }}
          draft={draftTasksVisible}
          draftCallback={() => {
            setDraftTasksVisible({
              isVisible: true,
              once: true,
            });
          }}
          role={userInfo?.roleId}
          renderCustomCard={renderCustomCard}
          slug={slug}
        />
      </div>
    </div>
  );
};

const Tasks = forwardRef<
  {
    theme: string;
    list: TaskItem[];
    hastasks: boolean;
    onClick: () => void;
    draft: any;
    draftCallback: () => void;
    renderCustomCard: (amountElements: boolean) => JSX.Element | null;
    role: number;
  },
  any
>(
  (
    {
      theme,
      list,
      hastasks,
      onClick,
      draft,
      draftCallback,
      renderCustomCard,
      role,
      buttonRef,
      isStaffOrPM,
    },
    ref
  ) => {
    const refCallback = useCallback((node: any) => {
      if (node !== null) {
        (ref as any).current.push(node);
      }
    }, []);

    return (
      <ul
        className={cx('list', 'task-list', {
          'task-list--alternate': checkPermission('STAFF') || role === 8,
        })}
      >
        {list?.map(
          (item: TaskItem): JSX.Element => (
            <li
              key={item.id}
              className={`task-list__item task-list__item--${theme}`}
            >
              <div
                className={`task-card task-card--${theme} task-card__col-head--${theme}`}
              >
                <span
                  className={`task-list__stage task-list__stage--${item.name}`}
                />
                <div className="task-card__flex">
                  <p className="task-card__headline">
                    {isStaffOrPM
                      ? STAGES_DATA_ALTERNATE[item.name]
                      : STAGES_DATA[item.name]}
                    <span className="task-card__counter">
                      {item?.taskList?.length}
                    </span>
                  </p>

                  {item.name === 'new' && checkPermission('CLIENT') && (
                    <Tooltip
                      placement="top"
                      trigger={['hover']}
                      overlay={<span>нова задача</span>}
                    >
                      <button
                        ref={buttonRef}
                        type="button"
                        className="task-list__btn"
                        onClick={onClick}
                      >
                        <span className="ci ci-plus" />
                      </button>
                    </Tooltip>
                  )}
                </div>
              </div>
              <div ref={refCallback} className="list--scroll">
                {item.name === 'new' &&
                  checkPermission('CLIENT') &&
                  renderCustomCard(!hastasks as boolean)}
                <TaskCard
                  role={role}
                  key={item.id}
                  draft={draft}
                  tasks={item?.taskList}
                  column={item.name}
                  draftCallback={draftCallback}
                />
              </div>
            </li>
          )
        )}
      </ul>
    );
  }
);

export default TaskList;
