import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import cx from 'classnames';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import InputMask from 'react-input-mask';
import Button from '../../../components/Button/Button';
import Heading from '../../../components/UI/Heading/Heading';
import Text from '../../../components/UI/Text/Text';
import Input from '../../../components/UI/Input/Input';
import Modal from '../../../components/Modal/Modal';
import { useThemeToggler } from '../../../context/ThemeContext';
import { AppDispatch } from '../../../store';
import {
  deleteAvatar,
  getUserData,
  updateAvatar,
  updatePassword,
  updateUserInfo,
} from '../../../store/user/thunk';
import {
  getProjectData,
  getStaffTasks,
  getUsersList,
} from '../../../store/projects/thunk';
import { Toggler } from '../Header';

import { NickNameSelect } from '../../../pages/auth/StaffRegister/StaffRegister';

import { ReactComponent as IconEdit } from '../../../media/icons/edit.svg';
import { ReactComponent as IconKey } from '../../../media/icons/icons8-key-14.svg';
import { ReactComponent as IconTelegram } from '../../../media/icons/telegram1.svg';
import { ReactComponent as IconLetter } from '../../../media/icons/mail2.svg';
import { ReactComponent as IconCamera } from '../../../media/icons/photo-camera-svgrepo-com.svg';
import { ReactComponent as IconBot } from '../../../media/icons/bot.svg';
import { ReactComponent as IconTrash } from '../../../media/icons/trash_full.svg';
import { ReactComponent as IconPlus } from '../../../media/icons/plus.svg';
import { ReactComponent as IconCheckbox } from '../../../media/icons/checkbox.svg';
import { checkPermission } from '../../../hooks/checkPermission';
import { getNicknames } from '../../../store/auth/thunk';
import { interceptorsInit } from '../../../helpers/axios';

const settingLabels = [
  'Повідомляти в телеграм про мої завдання',
  'Повідомляти в телеграм про чужі завдання',
  'Повідомляти на e-mail про мої завдання',
  'Повідомляти на e-mail про чужі завдання',
  'Залишати подвійні пробіли в тексті завдань',
];

const settingParams = [
  'tgMyTask',
  'tgOtherTask',
  'mailMyTask',
  'mailOtherTask',
  'doubleSpaces',
];

// const settingsData = [
//   {
//     param: 'tgMyTask',
//     value: 0,
//     label: 'Повідомляти в телеграм про мої завдання',
//   },
//   {
//     param: 'tgOtherTask',
//     value: 0,
//     label: 'Повідомляти в телеграм про чужі завдання',
//   },
//   {
//     param: 'mailMyTask',
//     value: 0,
//     label: 'Повідомляти на e-mail про мої завдання',
//   },
//   {
//     param: 'mailOtherTask',
//     value: 0,
//     label: 'Повідомляти на e-mail про чужі завдання',
//   },
//   {
//     param: 'doubleSpaces',
//     value: 0,
//     label: 'Залишати подвійні пробіли в тексті завдань',
//   },
// ];

const telegramBotLink = 'https://t.me/negayno_bot';

export const PersonalCabinet = ({ defaultIndex = 0 }): JSX.Element => {
  const { userInfo } = useSelector((state: any) => state.userData);
  const [telModal, setTelModal] = useState(false);
  const [isModalActive, setModalActive] = useState(false);
  const [activePersonalDataType, setActivePersonalDataType] = useState('');

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm();
  const dispatch: AppDispatch = useDispatch();
  const params = useParams();
  const { theme } = useThemeToggler();
  const { nicknames } = useSelector((state: any) => state.auth);

  const nicFirstaneOptions = useMemo(() => {
    if (!nicknames?.nicFirstname) return [];
    return Object.entries(nicknames?.nicFirstname).map(([key, value]: any) => ({
      value: value,
      label: value,
      nicId: key,
    }));
  }, [nicknames?.nicFirstname]);

  const nicLastnameOptions = useMemo(() => {
    if (!nicknames?.nicLastname) return [];
    return Object.entries(nicknames?.nicLastname).map(([key, value]: any) => ({
      value: value,
      label: value,
      nicId: key,
    }));
  }, [nicknames?.nicLastname]);

  const maskPhoneNumber = (e: any): void => {
    setValue('telephone', e.target.value);
  };

  const handlerChangeTel = handleSubmit((data) => {
    const { telephone } = data;
    if (!telephone.includes('_')) {
      dispatch(
        updateUserInfo({
          param: 'telephone',
          value: telephone.replace(/[^+\d]/g, ''),
        })
      );
      setTelModal(false);
    } else {
      setError('telephone', {
        type: 'manual',
        message: 'Введіть номер телефону',
      });
    }
  });

  const addFileHandler = useCallback(
    (e: any) => {
      if (e.target.files) {
        const file = e.target.files[0];
        if (file) {
          const data = new FormData();
          const blob = new Blob([file]);
          const blobFile = new File([blob], file.name, {
            type: file.type,
          });

          data.append('file[0]', blobFile);
          dispatch(updateAvatar(data))
            .unwrap()
            .then(() => {
              e.target.value = '';
              dispatch(getUserData());
              if (params.slug || params.slugtask) {
                dispatch(getUsersList(params.slug as string));
              }
              if (params.slug) {
                dispatch(getProjectData(params.slug as string));
              }
              if (checkPermission('STAFF')) {
                dispatch(getStaffTasks());
              }
            });
        }
      }
    },
    [params]
  );

  useEffect(() => {
    if (Object.keys(nicknames).length === 0) {
      dispatch(getNicknames());
    }
  }, [nicknames?.nicFirstname, nicknames?.nicLastname]);

  const personalCabinetDatas = [
    {
      type: 'name',
      title: "Змінити ім'я",
      subTitle: "Введіть нове ім'я",
      fieldType: 'text',
      fields: [
        {
          name: 'firstName',
          placeholder: "Ім'я",
          defaultValue: userInfo.firstName,
        },
        {
          name: 'lastName',
          placeholder: 'Прізвище',
          defaultValue: userInfo.lastName,
        },
      ],
      buttonSuccessText: "Змінити ім'я",
    },
    {
      type: 'tel',
      title: 'Змінити номер телефону',
      fieldType: 'tel',
      subTitle: 'Введіть новий номер телефону',
      fields: [
        {
          name: 'telephone',
          placeholder: 'Телефон',
          defaultValue: userInfo.telephone,
        },
      ],
      buttonSuccessText: 'Змінити номер',
    },
    {
      type: 'select',
      title: 'Змінити нікнейм',
      subTitle: 'Оберіть новий нікнейм',
      fields: [
        {
          name: 'nicFirstname',
          placeholder: 'Нікнейм',
          options: nicFirstaneOptions,
          defaultValue: {
            value: userInfo.nicFirstname,
            label: userInfo.nicFirstname,
          },
        },
        {
          name: 'nicLastname',
          placeholder: 'Нікнейм',
          options: nicLastnameOptions,
          defaultValue: {
            value: userInfo.nicLastname,
            label: userInfo.nicLastname,
          },
        },
      ],
      buttonSuccessText: 'Змінити нік',
    },
    {
      type: 'password',
      title: 'Змінити пароль',
      subTitle: 'Введіть новий пароль',
      fieldType: 'password',
      fields: [
        {
          name: 'password',
          placeholder: 'Пароль',
        },
      ],
      buttonSuccessText: 'Змінити пароль',
    },
  ];

  const actualFieldData = personalCabinetDatas.find(
    (item) => item.type === activePersonalDataType
  );

  return (
    <div className="personal-cabinet">
      {actualFieldData?.type && (
        <ModalUpdatePersonalData
          register={register}
          dispatch={dispatch}
          errors={errors}
          isActive={isModalActive}
          handleClose={() => setModalActive(false)}
          title={actualFieldData?.title}
          subTitle={actualFieldData?.subTitle}
          fieldType={actualFieldData?.fieldType}
          type={actualFieldData?.type}
          fieldData={actualFieldData}
          onChange={
            actualFieldData?.type === 'tel'
              ? maskPhoneNumber
              : (e: any) => {
                  console.log(
                    actualFieldData?.fields,
                    e.target.name,
                    e.target.value
                  );
                  setValue(e.target.name, e.target.value);
                }
          }
          buttonSuccessText={actualFieldData?.buttonSuccessText}
          handlerChangeData={handleSubmit}
          defaultValue={userInfo.firstName}
        />
      )}
      <Tabs defaultIndex={defaultIndex}>
        <TabList className="personal-cabinet__header">
          <Tab>Профіль</Tab>
          <Tab>Налаштування</Tab>
        </TabList>
        <div className="personal-cabinet__body">
          <TabPanel>
            <div
              className={cx('personal-cabinet__profile user-profile', {
                [`user-profile--${theme}`]: theme,
              })}
            >
              <div className="user-profile__top">
                <label htmlFor="avatar-input" className="user-profile__avatar">
                  <div className="user-profile__button-avatar avatar-action">
                    <label
                      htmlFor="avatar-input"
                      className="user-profile__avatar-label avatar-action__icon avatar-action__icon--add"
                    >
                      <IconPlus />
                    </label>
                    {userInfo.avatar?.url && (
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          dispatch(deleteAvatar()).then(() => {
                            if (params.slug || params.slugtask) {
                              dispatch(getUsersList(params.slug as string));
                            }
                            if (params.slug) {
                              dispatch(getProjectData(params.slug as string));
                            }
                          });
                        }}
                        className="avatar-action__icon avatar-action__icon--delete"
                      >
                        <IconTrash />
                      </span>
                    )}
                  </div>
                  {userInfo.avatar?.url ? (
                    <img src={userInfo.avatar.url} alt="avatar" />
                  ) : (
                    <IconCamera className="user-profile__avatar-icon" />
                  )}
                  <input
                    id="avatar-input"
                    className="user-profile__avatar-input"
                    onChange={addFileHandler}
                    accept=".png, .gif, .jpeg, .jpg"
                    type="file"
                  />
                  {userInfo?.avatar?.url && (
                    <div className="user-profile__edit">
                      <label
                        className="user-profile__avatar-label"
                        htmlFor="avatar-input"
                      >
                        <IconEdit className="user-profile__edit-icon" />
                      </label>
                    </div>
                  )}
                </label>
                <Heading
                  onClick={() => {
                    setActivePersonalDataType('name');
                    setModalActive(true);
                  }}
                  className="user-profile__title"
                  size="md"
                >
                  {userInfo.firstName} {userInfo.lastName}
                  <IconEdit className="user-profile__edit-icon" />
                </Heading>

                <Text
                  className="user-profile__text user-profile__email"
                  size="sm"
                  style={{ cursor: 'default' }}
                >
                  {userInfo.email}
                </Text>
                <Text
                  className="user-profile__text user-profile__tel"
                  size="sm"
                  onClick={() => {
                    setActivePersonalDataType('tel');
                    setModalActive(true);
                  }}
                >
                  {userInfo.telephone}
                  <IconEdit className="user-profile__edit-icon" />
                </Text>
                {checkPermission('STAFF') && (
                  <Heading
                    onClick={() => {
                      setActivePersonalDataType('select');
                      setModalActive(true);
                    }}
                    className="user-profile__title user-profile__title--staff"
                    size="sm"
                    as="h4"
                  >
                    {userInfo.nicFirstname} {userInfo.nicLastname}
                    <IconEdit className="user-profile__edit-icon" />
                  </Heading>
                )}
                <Button
                  onClick={() => {
                    setActivePersonalDataType('password');
                    setModalActive(true);
                  }}
                  view="third"
                  className="user-profile__button"
                >
                  <IconKey />
                  Змінити пароль
                </Button>
              </div>
              <div className="user-profile__bottom">
                <div className="user-profile__socials">
                  <Heading className="user-profile__social" size="md">
                    <IconTelegram />
                    Telegram-бот
                  </Heading>

                  <Toggler
                    key={userInfo?.tgBot}
                    variant="rounded"
                    onChange={(value: any) => {
                      dispatch(
                        updateUserInfo({
                          param: 'tgBot',
                          value: Number(value),
                        })
                      );
                    }}
                    defaultChecked={userInfo.tgBot}
                  />
                </div>
                {!!userInfo?.tgBot && userInfo?.telegramToken && (
                  <div className="header__chat-bot chat-bot">
                    {/* <h3 className="chat-bot__title">
                      <IconBot className="chat-bot__icon" />
                      Телеграм-бот
                    </h3> */}
                    <a
                      className="chat-bot__link"
                      target="_blank"
                      rel="noreferrer"
                      href={`${telegramBotLink}?start=${userInfo.telegramToken}`}
                    >
                      {telegramBotLink}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="user-profile__settings">
              {/* <div className="user-setting">
                  <Heading className="user-setting__title" size="sm">
                    <IconLang />
                    Змінити мову
                  </Heading>
                  <label className="user-setting__option">
                    <input type="checkbox" className="user-setting__checkbox" />
                    <div className="user-setting__option-overlay" />
                    Українська
                  </label>
                  <label className="user-setting__option">
                    <input type="checkbox" className="user-setting__checkbox" />
                    <div className="user-setting__option-overlay" />
                    Англійська
                  </label>
                  <label className="user-setting__option">
                    <input type="checkbox" className="user-setting__checkbox" />
                    <div className="user-setting__option-overlay" />
                    Іспанська
                  </label>
                </div> */}
              <div
                className={cx('user-setting', {
                  [`user-setting--${theme}`]: theme,
                })}
              >
                {/* <Heading className="user-setting__title" size="sm">
                  <IconLetter />
                  Повідомлення
                </Heading> */}
                <MessagesSettings
                  key={Date.now() * Math.random()}
                  messageSettingList={userInfo}
                  dispatch={dispatch}
                />
              </div>
            </div>
          </TabPanel>
        </div>
      </Tabs>
    </div>
  );
};

const MessagesSettings: React.FC<any> = ({ messageSettingList, dispatch }) => {
  const settingParamsArray = useMemo(
    () =>
      settingParams.map((param, i) => ({
        param,
        value: messageSettingList[param],
        label: settingLabels[i],
      })),
    [messageSettingList]
  );

  return settingParamsArray.map((item: any) => (
    <label key={item.param} className="user-setting__option">
      <input
        defaultChecked={item.value}
        onChange={(e) => {
          dispatch(
            updateUserInfo({
              param: item.param,
              value: Number(e.target.checked),
            })
          );
        }}
        type="checkbox"
        className="user-setting__checkbox"
      />
      <IconCheckbox />
      {item.label}
    </label>
  )) as any;
};

const ModalUpdatePersonalData: React.FC<any> = ({
  isActive,
  handleClose,
  title,
  subTitle,
  errors,
  onChange,
  buttonSuccessText,
  handlerChangeData,
  fieldData,
  register,
  dispatch,
}) => (
  <Modal
    title={title}
    isActive={isActive}
    size="sm"
    handleClose={handleClose}
    leftButton={false}
    headerModalClassName="settings-panel__modal-header"
    modalClassName="personal-cabinet__modal"
    bodyClassName="personal-cabinet__modal-body"
  >
    <div className="personal-cabinet__change-password">
      <div className="personal-cabinet__sub-title">{subTitle}</div>
      <div className="personal-cabinet__rename">
        {((fieldData.type === 'name' || fieldData.type === 'password') && (
          <>
            {fieldData.fields.map(({ name, defaultValue }: any) => (
              <Input
                key={name}
                defaultValue={defaultValue}
                type={fieldData.fieldType}
                style={{ borderColor: errors[name] && 'red' }}
                ref={register(name).ref}
                onChange={onChange}
                name={name}
              />
            ))}
          </>
        )) ||
          (fieldData.type === 'tel' && (
            <>
              {fieldData.fields.map(({ name, defaultValue }: any) => (
                <InputMask
                  key={name}
                  className="input__field"
                  mask="+380 (99) 999-9999"
                  defaultValue={defaultValue}
                  style={{ borderColor: errors[name] && 'red' }}
                  inputRef={register(name).ref}
                  {...register(name, {
                    required: 'Введіть номер телефону',
                  })}
                  onChange={onChange}
                  name={name}
                />
              ))}
            </>
          )) ||
          (fieldData.type === 'select' && (
            <>
              {fieldData.fields.map(({ name, options, defaultValue }: any) => (
                <NickNameSelect
                  key={name}
                  options={options}
                  name={name}
                  onchange={onchange}
                  defaultValue={defaultValue}
                  errors={errors}
                  register={register(name, {
                    required: "Заповніть обов'язкове поле",
                  })}
                />
              ))}
            </>
          ))}
      </div>
      <div className="personal-cabinet__rename-buttons">
        <Button
          onClick={handleClose}
          view="base"
          className="personal-cabinet__password"
        >
          Повернутися
        </Button>
        <Button
          onClick={handlerChangeData((data: any) => {
            Object.entries(data).forEach(([key, val]: any) => {
              const value = val.trim();
              if (
                value !==
                fieldData.fields.find((item: any) => item.name === key)
                  .defaultValue
              ) {
                dispatch(
                  updateUserInfo({
                    param: key,
                    value,
                  })
                )
                  .unwrap()
                  .then(() => {
                    handleClose();
                  });
              }
            });
          })}
          view="default"
          className="personal-cabinet__password"
        >
          {buttonSuccessText}
        </Button>
      </div>
    </div>
  </Modal>
);
// {/* <Modal
// title="Змінити пароль"
// isActive={passwordModal}
// size="sm"
// handleClose={() => setPasswordModal(false)}
// leftButton={false}
// headerModalClassName="settings-panel__modal-header"
// modalClassName="personal-cabinet__modal"
// bodyClassName="personal-cabinet__modal-body"
// >
// <div className="personal-cabinet__change-password">
//   <div className="personal-cabinet__sub-title">Новий пароль</div>
//   <Input
//     defaultValue=""
//     type="text"
//     placeholder="Новий пароль"
//     style={{ borderColor: errors.password && 'red' }}
//     ref={register('pasword').ref}
//     onChange={(e: any) => {
//       setValue('pasword', e.target.value);
//     }}
//   />
//   <Button
//     onClick={() => {
//       dispatch(
//         updatePassword({
//           param: 'password',
//           value: getValues('pasword'),
//         })
//       )
//         .unwrap()
//         .then(({ token }) => {
//           if (token) {
//             interceptorsInit(token);
//             setPasswordModal(false);
//             setValue('pasword', '');
//           }
//         });
//     }}
//     view="default"
//     className="personal-cabinet__password-button"
//   >
//     Замінити пароль
//   </Button>
// </div>
// </Modal>
// <Modal
// title="Змінити телефон"
// isActive={telModal}
// size="sm"
// handleClose={() => setTelModal(false)}
// leftButton={false}
// headerModalClassName="settings-panel__modal-header"
// modalClassName="personal-cabinet__modal"
// bodyClassName="personal-cabinet__modal-body"
// >
// <div className="personal-cabinet__change-password">
//   <div className="personal-cabinet__sub-title">
//     Введіть номер телефону
//   </div>
//   <div className="personal-cabinet__rename">
//     <InputMask
//       className="input__field"
//       mask="+380 (99) 999-9999"
//       defaultValue={userInfo.telephone}
//       style={{ borderColor: errors.telephone && 'red' }}
//       inputRef={register('telephone').ref}
//       {...register('telephone', {
//         required: 'Введіть номер телефону',
//       })}
//       onChange={maskPhoneNumber}
//     />
//   </div>
//   <div className="personal-cabinet__rename-buttons">
//     <Button
//       onClick={() => {
//         setTelModal(false);
//       }}
//       view="base"
//       className="personal-cabinet__password"
//     >
//       Повернутися
//     </Button>
//     <Button
//       onClick={handlerChangeTel}
//       view="default"
//       className="personal-cabinet__password"
//     >
//       Змінити номер
//     </Button>
//   </div>
// </div>
// </Modal>
// {/* {checkPermission('CLIENT') && ( */}
// <Modal
// title="Змінити ім'я"
// isActive={renameModal}
// size="sm"
// handleClose={() => setRenameModal(false)}
// leftButton={false}
// headerModalClassName="settings-panel__modal-header"
// modalClassName="personal-cabinet__modal"
// bodyClassName="personal-cabinet__modal-body"
// >
// <div className="personal-cabinet__change-password">
//   <div className="personal-cabinet__sub-title">
//     Введіть нове ім&#39;я
//   </div>
//   <div className="personal-cabinet__rename">
//     {getValues('firstName')}
//     <Input
//       defaultValue={userInfo.firstName}
//       type="text"
//       placeholder="Ім&#39;я"
//       style={{ borderColor: errors.firstName && 'red' }}
//       ref={register('firstName').ref}
//       onChange={(e: any) => {
//         setValue('firstName', e.target.value);
//       }}
//     />
//     <Input
//       defaultValue={userInfo.lastName}
//       type="text"
//       placeholder="Прізвище"
//       style={{ borderColor: errors.firstName && 'red' }}
//       ref={register('lastName').ref}
//       onChange={(e: any) => {
//         setValue('lastName', e.target.value);
//       }}
//     />
//   </div>
//   <div className="personal-cabinet__rename-buttons">
//     <Button
//       onClick={() => {
//         setRenameModal(false);
//       }}
//       view="base"
//       className="personal-cabinet__password"
//     >
//       Повернутися
//     </Button>
//     <Button
//       onClick={handleSubmit((data) => {
//         console.log(data);
//         if (data.firstName !== userInfo.firstName) {
//           dispatch(
//             updateUserInfo({
//               param: 'firstName',
//               value: data.firstName,
//             })
//           );
//         }
//         if (data.lastName !== userInfo.lastName) {
//           dispatch(
//             updateUserInfo({
//               param: 'lastName',
//               value: data.lastName,
//             })
//           );
//         }
//         setRenameModal(false);
//       })}
//       view="default"
//       className="personal-cabinet__password"
//     >
//       Змінити ім&apos;я
//     </Button>
//   </div>
// </div>
// </Modal>
// {/* )} */}
// {checkPermission('STAFF') && (
// <Modal
//   title="Змінити нікнейм"
//   isActive={renameNicModal}
//   size="sm"
//   handleClose={() => setRenameNicModal(false)}
//   leftButton={false}
//   headerModalClassName="settings-panel__modal-header"
//   modalClassName="personal-cabinet__modal"
//   bodyClassName="personal-cabinet__modal-body"
// >
//   <div className="personal-cabinet__change-password">
//     <div className="personal-cabinet__sub-title">
//       Оберіть новий нікнейм
//     </div>
//     <div className="personal-cabinet__rename">
//       {/* <NickNameSelect
//         options={nicFirstaneOptions}
//         name="nicFirstname"
//         onchange={(e: any) => {
//           setValue('nicFirstname', +e.nicId as any);
//         }}
//         defaultValue={{
//           value: userInfo.nicFirstname,
//           label: userInfo.nicFirstname,
//         }}
//         errors={errors}
//         register={register('nicFirstname', {
//           required: "Заповніть обов'язкове поле",
//         })}
//       /> */}
//       {/* <Input
//         defaultValue={userInfo.nicFirstname}
//         type="text"
//         placeholder="Ім&#39;я"
//         style={{ borderColor: errors.nicFirstname && 'red' }}
//         ref={register('nicFirstname').ref}
//         onChange={(e: any) => {
//           setValue('nicFirstname', e.target.value);
//         }}
//       /> */}

//       {/* <NickNameSelect
//         options={nicLastnameOptions}
//         name="nicFirstname"
//         onchange={(e: any) => {
//           setValue('nicFirstname', +e.nicId as any);
//         }}
//         defaultValue={{
//           value: userInfo.nicLastname,
//           label: userInfo.nicLastname,
//         }}
//         errors={errors}
//         register={register('nicFirstname', {
//           required: "Заповніть обов'язкове поле",
//         })}
//       /> */}

//       {/* <Input
//         defaultValue={userInfo.nicLastname}
//         type="text"
//         placeholder="Прізвище"
//         style={{ borderColor: errors.nicLastname && 'red' }}
//         ref={register('nicLastname').ref}
//         onChange={(e: any) => {
//           setValue('nicLastname', e.target.value);
//         }}
//       /> */}
//     </div>
//     <div className="personal-cabinet__rename-buttons">
//       <Button
//         onClick={() => {
//           setRenameNicModal(false);
//         }}
//         view="base"
//         className="personal-cabinet__password"
//       >
//         Повернутися
//       </Button>
//       <Button
//         onClick={handleSubmit((data) => {
//           console.log(data);
//           // if (data.nicFirstname !== userInfo.nicFirstname) {
//           dispatch(
//             updateUserInfo({
//               param: 'nicFirstname',
//               value: data.nicFirstname,
//             })
//           );
//           // }
//           // if (data.nicLastname !== userInfo.nicLastname) {
//           dispatch(
//             updateUserInfo({
//               param: 'nicLastname',
//               value: data.nicLastname,
//             })
//           );
//           // }
//           setRenameNicModal(false);
//         })}
//         // onClick={handleSubmit((data) => {
//         //   console.log(data);
//         //   // if (data.nicFirstname !== userInfo.nicFirstname) {
//         //   dispatch(
//         //     updateUserInfo({
//         //       param: 'nicFirstname',
//         //       value: data.nicFirstname,
//         //     })
//         //   );
//         //   // }
//         //   // if (data.nicLastname !== userInfo.nicLastname) {
//         //   dispatch(
//         //     updateUserInfo({
//         //       param: 'nicLastname',
//         //       value: data.nicLastname,
//         //     })
//         //   );
//         //   // }
//         //   setRenameNicModal(false);
//         // })}
//         view="default"
//         className="personal-cabinet__password"
//       >
//         Змінити нікнейм
//       </Button>
//     </div>
//   </div>
// </Modal>
// )} */}
