import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { SubmitHandler, set, useForm } from 'react-hook-form';
import cx from 'classnames';

import ThemeSwitcher from '../../components/ThemeSwitcher/ThemeSwitcher';
import Balance from '../../components/Balance/Balance';
import AccountPopup from './AccountPopup/AccountPopup';
import Heading from '../../components/UI/Heading/Heading';

import logoLightTheme from '../../logo.svg';
import logoDarkTheme from '../../logo--dark-theme.svg';
import { useThemeToggler } from '../../context/ThemeContext';
import { checkPermission } from '../../hooks/checkPermission';
import User from '../../components/UsersList/User/User';
import Dot from '../../components/UI/Dot/Dot';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

import Notification from '../../components/Notification/Notification';

import { ReactComponent as IconBalance } from '../../media/icons/balance.svg';
import { ReactComponent as IconBonus } from '../../media/icons/bonus.svg';

import BalanceOperations from '../../components/BalanceOperations/BalanceOperations';
import Modal from '../../components/Modal/Modal';
import {
  fetchBalance,
  fetchBalanceTransactions,
  fetchBonusesStatistic,
  refillBalance,
  fetchBalanceStatistic,
  fetchProjectBalanceTransactions,
  fetchBillingBonuses,
  fetchBonusesTransactions,
  moveBonusesToBalance,
  fetchProjectBalance,
  fetchProjectBalanceStatistic,
  balanceRequest,
  getSingleBalanceTransaction,
  getSingleProjectBalanceTransaction,
} from '../../store/billing/thunk';
import { AppDispatch } from '../../store';
import Button from '../../components/Button/Button';
import Input from '../../components/UI/Input/Input';

import { getUserInfo } from '../../store/user/store';
import {
  PROJECT_ROLES,
  balanceTansactionTypes,
  bonusesTansactionTypes,
} from '../../common/constant';
import {
  balanceChartLabels,
  bonusesChartLabels,
  staffBalanceChartLabels,
} from '../../helpers/constant';
import { useSocketListener } from '../../hooks/useSocketListener';
import { inputNumberHandler } from '../../helpers/common';

import './Header.scss';
import { clearBalanceTransactions } from '../../store/billing/store';

export default function Header(): JSX.Element {
  const { theme } = useThemeToggler();
  const [isAccountPopup, toggleAccountPopup] = useState<boolean>(false);
  const [isNotificationVisible, setNotificationVisible] =
    useState<boolean>(false);
  const logo = theme === 'light' ? logoLightTheme : logoDarkTheme;
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const { userInfo } = useSelector(
    (state: { userData: any }) => state.userData
  );
  const { projectInfo } = useSelector(
    (state: { projectData: any }) => state.projectData
  );
  const ref = useRef<HTMLLIElement>(null);
  const notifRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, (e: any) => {
    if (e.target.closest('.personal-cabinet__modal')) return;
    toggleAccountPopup(false);
  });
  useOnClickOutside(notifRef, (e: any) => {
    if (!e.target.closest('.notification-btn')) {
      setNotificationVisible(false);
    }
  });
  const {
    balance,
    balanceTransactions,
    bonusesStatistic,
    balanceStatistic,
    bonuses,
    isTheLastBalanceTransaction,
    bonusesTransactions,
  } = useSelector((state: any) => state.billingData);
  const [isShowModalBalance, setIsShowModalBalance] = useState(false);
  const [paymentModalShow, setPaymentModalShow] = useState(false);
  const [bonusesModalShow, setBonusesModalShow] = useState(false);
  const [isShowModalBonuse, setIsShowModalBonuse] = useState(false);
  const { nowViewed } = useSelector((state: any) => state.projectData.inform);
  const {
    register,
    setError,
    setValue,
    formState: { errors },
  } = useForm();

  const bonusRef = useRef<any>(null);
  const params = useParams();
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector((state: any) => state.userData.userInfo);

  const isStaff = checkPermission('STAFF');
  const isClient = checkPermission('CLIENT');

  useEffect(() => {
    dispatch(getUserInfo());
  }, [params.slug]);

  const balanceEventHandler = useCallback(
    ({ userId, transactionId }: { userId: string; transactionId: string }) => {
      if (userId === userInfo?.id) {
        dispatch(fetchBalance());
        // dispatch(
        //   fetchBalanceTransactions({
        //     listType: 'all',
        //     page: 0,
        //   })
        // );
        dispatch(getSingleBalanceTransaction(transactionId));
        dispatch(fetchBalanceStatistic());
      }
    },
    [userInfo?.id]
  );

  const bonusesEventHandler = useCallback(() => {
    dispatch(fetchBonusesTransactions());
    dispatch(fetchBonusesStatistic());
    dispatch(fetchBillingBonuses());
  }, []);

  const projectBalanceEventHandler = useCallback(
    ({ transactionId }: any) => {
      if (params.slug) {
        console.log('transactionId', transactionId);
        if (userInfo?.roleId === PROJECT_ROLES.productOwner) {
          dispatch(fetchProjectBalance(params.slug));
          dispatch(fetchProjectBalanceStatistic(params.slug));
          // dispatch(
          //   fetchProjectBalanceTransactions({
          //     slug: params.slug as string,
          //     page: 0,
          //   })
          // );
          dispatch(
            getSingleProjectBalanceTransaction({
              transactionId,
              slug: params.slug as string,
            })
          );
        }
      }
    },
    [params.slug, userInfo?.roleId]
  );

  const getBonusesHandler = useCallback(
    (value: any) => {
      const res = dispatch(
        moveBonusesToBalance({
          sum: value,
        })
      );
      res.unwrap().then((data) => {
        if (data?.success) {
          setBonusesModalShow(false);
          return res;
        }
      });

      return res;
    },
    [bonusRef]
  );

  useSocketListener('projectBalanceEvent', projectBalanceEventHandler);
  useSocketListener('balanceEvent', balanceEventHandler);
  useSocketListener('bonusEvent', bonusesEventHandler);

  const balanceTransactionsPage = useRef<number>(1);

  useEffect(() => {
    if (!checkPermission('MANAGER')) {
      dispatch(fetchBalance());
    }
    // here is for staff working
    // dispatch(fetchBillingBonuses());
  }, []);

  useEffect(() => {
    if (bonusRef.current) {
      bonusRef.current.focus();
    }
  }, [bonusesModalShow]);

  const balanceChartStatistic = useMemo(
    () => balanceStatistic?.statistic?.chart,
    [balanceStatistic]
  );
  const bonusesChartStatistic = useMemo(
    () => bonusesStatistic?.statistic?.chart,
    [bonusesStatistic]
  );

  const modalTitle = checkPermission('CLIENT')
    ? 'Поповнити баланс'
    : 'Запит на виведення';

  return (
    <header className={`header header--${theme}`}>
      <NavLink to={checkPermission('CLIENT') ? '/' : '/tasks/'} replace>
        <img src={logo} alt="Home" height={30} className="header__logo" />
      </NavLink>
      <ul className="list header__list">
        {!isMobile && (
          <>
            <li className="header__item header__item--balance">
              <div
                onClick={() => {
                  dispatch(
                    fetchBalanceTransactions({
                      listType: 'all',
                      lastId: 0,
                    })
                  );
                  dispatch(fetchBalanceStatistic());
                  setIsShowModalBalance(true);
                }}
              >
                {((isStaff && Boolean(user?.salary)) || isClient) && (
                  // (balance?.balance === null || balance?.balance === 0))) &&
                  <>
                    <IconBalance />
                    <Balance
                      className="header__balance"
                      money={balance?.balance === null ? '0' : balance?.balance}
                    />
                  </>
                )}
              </div>
            </li>
            {/* <li className="header__item header__item--balance">
              <div
                onClick={() => {
                  dispatch(fetchBonusesStatistic());
                  dispatch(fetchBonusesTransactions());
                  setIsShowModalBonuse(true);
                }}
              >
                {(bonuses?.balance ||
                  bonuses?.balance === null ||
                  bonuses?.balance === 0) && (
                  <>
                    <IconBonus className="header__item--icon-balance" />
                    <Balance
                      money={bonuses?.balance === null ? 0 : bonuses?.balance}
                    />
                  </>
                )}
              </div>
            </li> */}
          </>
        )}
        <Modal
          title="Гаманець"
          isActive={isShowModalBalance}
          size="lg"
          handleClose={() => {
            setIsShowModalBalance(false);
            dispatch(clearBalanceTransactions());
          }}
          leftButton={false}
          headerModalClassName="settings-panel__modal-header"
        >
          <BalanceOperations
            transactionTypes={balanceTansactionTypes}
            balance={balance?.balance}
            isLast={
              !(
                balanceTransactions?.transactionList[
                  balanceTransactions?.transactionList?.length - 1
                ]?.id === balanceTransactions?.lastId
              )
            }
            onShowMore={() => {
              // balanceTransactionsPage.current += 1;
              // if (
              //   Math.ceil(balanceTransactions?.count / 20) >=
              //   balanceTransactionsPage.current
              // ) {
              console.log(balanceTransactions);
              dispatch(
                fetchBalanceTransactions({
                  listType: 'all',
                  lastId:
                    balanceTransactions?.transactionList[
                      balanceTransactions?.transactionList?.length - 1
                    ]?.id,
                })
              );
              // }
            }}
            buttonTitle={modalTitle}
            onRefill={() => {
              setPaymentModalShow(true);
            }}
            transactions={balanceTransactions}
            statistic={balanceChartStatistic}
            chartLabels={
              checkPermission('CLIENT')
                ? balanceChartLabels
                : staffBalanceChartLabels
            }
          />
        </Modal>
        <Modal
          title={modalTitle}
          isActive={paymentModalShow}
          size="md"
          handleClose={() => setPaymentModalShow(false)}
          leftButton={false}
          modalClassName="header__modal-refill-balance"
          headerModalClassName="settings-panel__modal-header"
        >
          <PaymentBlock
            onClose={() => setPaymentModalShow(false)}
            theme={theme}
          />
        </Modal>
        <Modal
          title="Поповнити баланс"
          isActive={bonusesModalShow}
          size="md"
          handleClose={() => {
            setBonusesModalShow(false);
            setError('bonuses', {
              type: 'manual',
              message: '',
            });
          }}
          leftButton={false}
          modalClassName="header__modal-refill-balance"
          headerModalClassName="settings-panel__modal-header"
        >
          <Payment paymentHandler={getBonusesHandler} />
        </Modal>
        <Modal
          title="Накопичено бонусів"
          isActive={isShowModalBonuse}
          size="lg"
          handleClose={() => setIsShowModalBonuse(false)}
          leftButton={false}
          headerModalClassName="settings-panel__modal-header"
        >
          <BalanceOperations
            isBonuses
            statistic={bonusesChartStatistic}
            balance={bonuses?.balance}
            transactions={bonusesTransactions}
            transactionTypes={bonusesTansactionTypes}
            chartLabels={bonusesChartLabels}
            onRefill={() => setBonusesModalShow(true)}
            buttonTitle="Вивести бонуси на баланс"
          />
        </Modal>

        <li className="header__item">
          <ThemeSwitcher />
        </li>
        <li className="header__item">
          <button
            onClick={() => {
              if (!isNotificationVisible) {
                setNotificationVisible(true);
              } else {
                setNotificationVisible(false);
              }
            }}
            className="ci ci-notification_outline notification-btn header__notification-btn"
          >
            {!!nowViewed && (
              <Dot
                variant="warning"
                size="md"
                className="notification-btn__marker"
              >
                {nowViewed > 99 ? ':D' : nowViewed}
              </Dot>
            )}
          </button>
          <Notification
            isVisible={isNotificationVisible}
            setVisible={setNotificationVisible}
            ref={notifRef}
          />
        </li>
        <li className="header__item" ref={ref}>
          <button
            type="button"
            className="header__btn"
            onClick={() => {
              toggleAccountPopup(!isAccountPopup);
            }}
          >
            <User isOnline={false} user={userInfo} size="md" />
          </button>
          {isAccountPopup && (
            <AccountPopup
              onClose={() => toggleAccountPopup(false)}
              showModalBalance={setIsShowModalBalance}
              showModalBonuses={setIsShowModalBonuse}
              balance={userInfo?.balance || 0}
              bonuses={userInfo?.balance || 0}
            />
          )}
        </li>
      </ul>
    </header>
  );
}

export const Toggler = ({
  children,
  theme,
  size,
  variant = '',
  onChange = () => {},
  defaultChecked = false,
  disabled = false,
  checkboxClassName = '',
  className = '',
  overlayClassName = '',
}: any): JSX.Element => (
  <label
    className={cx(
      'toggler',
      {
        [`toggler--${variant}`]: variant,
        [`toggler--${theme}`]: theme,
        [`toggler--${size}`]: size,
      },
      className
    )}
    style={{
      cursor: disabled ? 'not-allowed' : 'pointer',
      opacity: disabled ? 0.5 : 1,
    }}
  >
    <input
      disabled={disabled}
      defaultChecked={defaultChecked}
      onChange={(val) => onChange(val.target.checked)}
      type="checkbox"
      className={cx('toggler__checkbox', checkboxClassName)}
    />
    <div className={cx('toggler__overlay', overlayClassName)} />
    {children}
  </label>
);

interface IPaymentBlockProps {
  onClose: () => void;
  theme: string;
}

const PaymentBlock: React.FC<IPaymentBlockProps> = ({ onClose, theme }) => {
  const dispatch: AppDispatch = useDispatch();
  const [step, setStep] = useState(checkPermission('CLIENT') ? 0 : 1);
  const [isActive, setIsActive] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);

  const payVariantRef = useRef<any>(null);

  const onSubmit: SubmitHandler<any> = (value) => {
    const res = dispatch(
      checkPermission('CLIENT')
        ? refillBalance({
            sum: value,
            paymentMethod,
          })
        : balanceRequest({
            sum: value,
          })
    );
    res.unwrap().then((data) => {
      if (data?.status === 200 || data?.success) {
        onClose();
        return res;
      }
    });

    return res;
  };

  const handlePaymentMethod = useCallback(() => {
    setStep(step + 1);
    setPaymentMethod(payVariantRef.current?.value);
  }, []);

  return step === 0 ? (
    <div
      className={cx('payment-block', {
        [`payment-block--${theme}`]: theme,
      })}
    >
      <div className="payment-block__inner">
        <Heading className="payment-block__title" size="sm">
          Оберіть засіб поповнення рахунку
        </Heading>
        <div className="payment-block__row">
          <label
            className={cx('payment-block__variant', {
              'payment-block__variant--active': isActive,
            })}
            htmlFor="payment"
          >
            <input
              ref={payVariantRef}
              onChange={() => {
                setIsActive(true);
              }}
              type="radio"
              id="payment"
              value={5}
              defaultChecked={isActive}
            />
            Розрахунковий рахунок
          </label>
        </div>
        {isActive && (
          <Button
            className="payment-block__button"
            onClick={handlePaymentMethod}
            view="default"
          >
            Продовжити
          </Button>
        )}
      </div>
    </div>
  ) : (
    <Payment
      paymentHandler={onSubmit}
      handleBack={
        checkPermission('CLIENT')
          ? () => {
              setStep(step - 1);
            }
          : null
      }
    />
  );
};

export const Payment: React.FC<any> = ({ paymentHandler, handleBack }: any) => {
  const {
    register,
    handleSubmit,
    setFocus,
    setError,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const timer = setTimeout(() => {
      setFocus('payment');
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const onSubmit: SubmitHandler<any> = async (data) => {
    const res = await paymentHandler(data.payment);
    if (res) {
      setError('payment', {
        type: 'server',
        message: res.payload?.response?.data?.error,
      });
    }
  };

  return (
    <div className="payment-block">
      <form onSubmit={handleSubmit(onSubmit)} className="payment-block__inner">
        <Heading className="payment-block__title" size="sm">
          Введіть суму
        </Heading>
        <div className="payment-block__row">
          <Input
            min={1}
            maxLength={6}
            type="number"
            style={{ borderColor: errors.payment && 'red' }}
            onKeyDown={inputNumberHandler}
            {...register('payment', {
              required: 'Введіть суму',
            })}
          />
          {errors.payment?.message && (
            <span className="payment-block__error">
              {errors.payment.message as any}
            </span>
          )}
        </div>
        <div className="payment-block__buttons">
          {handleBack && (
            <Button onClick={handleBack} view="third">
              Назад
            </Button>
          )}
          <Button type="submit" view="default">
            Продовжити
          </Button>
        </div>
      </form>
    </div>
  );
};
