import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Layout from './layout/Layout/Layout';
import AuthLayout from './layout/AuthLayout/AuthLayout';
import AppLayout from './layout/AppLayout/AppLayout';
import LoginPage from './pages/auth/Login/Login';
import ClientRegister from './pages/auth/ClientRegister/ClientRegister';
import RestorePassword from './pages/auth/RestorePassword/RestorePassword';
import SendPassword from './pages/auth/SendPassword/SendPassword';
import Dashboard from './pages/dashboard/Dashboard';
import MainPage from './pages/dashboard/MainPage/MainPage';
import { useAuth } from './context/AuthContext';
import AuthProvider from './context/AuthProvider';
import { ThemeProvider } from './context/ThemeContext';

import { AppDispatch } from './store';

import { getNotificationList } from './store/projects/thunk';
import { getFilesRules, getUserData } from './store/user/thunk';
import { useSocketListener } from './hooks/useSocketListener';
import { useAuthErrorHandler } from './hooks/useAuthErrorHandler';
import { interceptorsInit, projectInstance } from './helpers/axios';

import { handleSignOut } from './layout/Header/AccountPopup/AccountPopup';
import { checkPermission } from './hooks/checkPermission';
import StaffRegister from './pages/auth/StaffRegister/StaffRegister';
import useWakeUpMonitor from './hooks/useWakeUpMonitor';
import { loginAs, logout } from './store/auth/thunk';

const LoginAs = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { key } = useParams();

  useEffect(() => {
    dispatch(loginAs(key as any) as any)
      .unwrap()
      .then(() => {
        navigate('/');
        window.location.reload();
      })
      .catch(() => {
        logout();
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        navigate('/login/');
      });
    console.log(key);
  }, []);

  return <></>;
};

let n: any;
let ins: any;

export const setNavigate = (navFn: any): void => {
  n = navFn;
};

export const getNavigate = (): any => n;

export const setInterseptors = (): void => {
  ins = interceptorsInit;
};

export const getInterseptior = (): any => ins;

export const RequireAuth = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.isLoggedIn) {
    return <Navigate to="/login/" state={{ from: location }} replace />;
  }

  return children;
};

const App = (): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const handleAuthError = useAuthErrorHandler();

  const user = useMemo(() => localStorage.getItem('user'), []);

  const informerHandler = useCallback(() => {
    dispatch(getNotificationList());
  }, []);

  const userHandler = useCallback(() => {
    dispatch(getUserData());
  }, []);

  // useWakeUpMonitor(handleWakeUp);
  useSocketListener('user', userHandler);
  useSocketListener('informer', informerHandler);

  // console.log(checkPermission('CLIENT'));

  useEffect(() => {
    setNavigate(navigate);
    setInterseptors();
  }, [navigate]);

  useEffect(() => {
    // here is need for sync login/logout actions between tabs
    window.addEventListener('storage', (e) => {
      if (e.key === 'user') {
        if (e.newValue) {
          navigate('/');
        } else {
          // handleSignOut(dispatch, navigate);
          navigate('/login/');
        }
      }
    });
  }, []);

  useLayoutEffect(() => {
    if (user) {
      const pathFromStorage = localStorage.getItem('fromPath');

      if (pathFromStorage) {
        navigate(pathFromStorage);
        localStorage.removeItem('fromPath');
      }
    } else {
      const path = window.location.pathname;
      if (!path.includes('login') && !path.includes('register')) {
        localStorage.setItem('fromPath', path);
      }
    }
  }, [user]);

  useEffect(() => {
    projectInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          handleAuthError();
        }
        return Promise.reject(error);
      }
    );
  }, [handleAuthError]);

  useEffect(() => {
    if (user) {
      const userObj = JSON.parse(user);
      const { groupId } = userObj;
      if (groupId !== 3 && groupId !== 4 && groupId !== 2) {
        handleSignOut(dispatch, navigate);
      }
    }
  }, [user, handleSignOut, handleAuthError]);

  useEffect(() => {
    function init100vh(): void {
      function setHeight(): void {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      }
      setHeight();
      window.addEventListener('resize', setHeight);
    }

    init100vh();

    const searchedWord = 'task-';
    const oldUrl = location.pathname;
    const oldTask = oldUrl.split(searchedWord)[1];
    if (oldUrl.includes(searchedWord)) {
      navigate(`/task/${oldTask}/`);
    }
    if (!oldUrl.endsWith('/')) {
      navigate(`${oldUrl}/`);
    }

    return () => {
      window.removeEventListener('resize', init100vh);
    };
  }, []);

  // need to re-render app when online status changes
  // useEffect(() => {
  //   const onlineHandler = (): void => {
  //     setAppKey(Date.now().toLocaleString());
  //   };
  //   window.addEventListener('online', onlineHandler);
  //   return () => {
  //     window.removeEventListener('online', onlineHandler);
  //   };
  // }, []);

  return (
    <AuthProvider>
      <ThemeProvider>
        <Routes>
          {/* {!user ? ( */}
          <Route element={<AuthLayout />}>
            <Route element={<AppLayout />}>
              <Route path="/login/" element={<LoginPage />} />
              <Route path="/register/" element={<ClientRegister />} />
              <Route path="/uregister/" element={<StaffRegister />} />
              <Route path="/restore-password/" element={<RestorePassword />} />
              <Route
                path="/restore-password/:token"
                element={<RestorePassword />}
              />
              <Route path="/send-password/" element={<SendPassword />} />
            </Route>
          </Route>
          {/* ) : ( */}
          <Route
            path="*"
            element={
              <RequireAuth>
                <Routes>
                  <Route element={<Layout />}>
                    <Route element={<AppLayout />}>
                      {checkPermission('CLIENT') && (
                        <>
                          <Route path="*" element={<Navigate to="/" />} />
                          <Route path="/" element={<Dashboard />} />
                          <Route
                            path="/project/:slug/"
                            element={<MainPage />}
                          />
                        </>
                      )}
                      {(checkPermission('STAFF') ||
                        checkPermission('MANAGER')) && (
                        <>
                          <Route path="*" element={<MainPage />} />
                        </>
                      )}
                      <Route path="/task/:slugTask/" element={<MainPage />} />
                    </Route>
                  </Route>
                </Routes>
              </RequireAuth>
            }
          />
          {/* )} */}
          <Route path="/login-as/:key" element={<LoginAs />} />
          <Route path="*" element={<Navigate to="/login/" />} />
        </Routes>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;
