import React, { useCallback, useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import Tooltip from 'rc-tooltip';

import Heading from '../UI/Heading/Heading';
import FileExtensions from '../UI/FileExtensions/FileExtensions';
import Text from '../UI/Text/Text';
import Spinner from '../UI/Spinner/Spinner';
import AutoResizeTextarea from '../../forms/AutoResizeTextarea/AutoResizeTextarea';

import { ReactComponent as IconDownload } from '../../media/icons/download.svg';
import { ReactComponent as IconCancel } from '../../media/icons/off_outline_close.svg';
import { ReactComponent as IconClose } from '../../media/icons/close_big.svg';
import { ReactComponent as IconTrash } from '../../media/icons/trash_full.svg';
import { ReactComponent as IconCheck } from '../../media/icons/check.svg';

import { convertBytesToKbOrMbOrGb } from '../../helpers/common';

import './FileAttachment.scss';
import { imgExtensions } from '../../helpers/constant';
import { useThemeToggler } from '../../context/ThemeContext';

interface IFile {
  id: string;
  name: string;
  extension: string;
  thumb: string;
  size: number;
  created_at: string;
  color: string;
  updated_at: string;
  url: string;
  type: string;
  editName?: string;
}

interface FileAttachmentProps {
  file: IFile;
  largeFile?: boolean;
  className?: string;
  progress?: number | null;
  onCancel?: () => void;
  onDelete?: () => void;
  onOpen?: (file?: any) => void;
  onRename?: any;
  canDelete?: boolean;
  notSupported?: boolean;
}

export const FileAttachment: React.FC<FileAttachmentProps> = ({
  file,
  largeFile,
  className,
  progress,
  notSupported = false,
  canDelete = false,
  onCancel = () => {},
  onDelete = () => {},
  onOpen = () => {},
  onRename = () => {},
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editName, setEditName] = useState(file.editName);
  const [isFocused, setIsFocused] = useState(false);
  const [isFileName, setIsFileName] = useState(file?.name);
  const ref = useRef<HTMLInputElement>(null);
  const { theme } = useThemeToggler();

  useEffect(() => {
    setIsFileName(file?.name);
  }, [file?.name]);

  return (
    <div
      className={cx(
        'file-attachment',
        {
          'file-attachment--loaded': file.thumb,
          [`file-attachment--${theme}`]: theme,
        },
        className
      )}
      // style={{
      //   cursor: canDelete ? 'default' : 'pointer',
      // }}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
          if (file?.url && file.type.includes('image')) {
            onOpen(file);
          } else {
            console.log('file', file);
          }
        }}
        className={cx(
          'file-attachment__left',
          !notSupported &&
            file.type.includes('image') &&
            file.type !== 'image/svg+xml'
            ? 'file-attachment__left--img'
            : 'file-attachment__left--file'
        )}
        style={{
          cursor: file.type.includes('image') ? 'pointer' : 'auto',
          backgroundColor: file.type.includes('image')
            ? file?.color
            : 'inherit',
          borderRadius: file.type.includes('image') ? 'inherit' : '0',
        }}
      >
        {!file.url ? (
          !notSupported ? (
            <Spinner className="file-attachment__loader" size="sm" />
          ) : (
            <IconClose className="file-attachment__close-icon" />
          )
        ) : !imgExtensions.includes(file?.extension.toLowerCase()) ? (
          <FileExtensions
            className="file-attachment__file"
            type={file.extension}
            // type={
            //   videoExtensions.includes(file?.extension)
            //     ? 'video'
            //     : file?.extension
            // }
          />
        ) : (
          <img
            className="file-attachment__img"
            src={!file?.url.includes('ico') ? file?.thumb : file?.url}
            alt={file?.name}
          />
        )}
      </div>
      <div className="file-attachment__right">
        {canDelete && isEditing ? (
          <AutoResizeTextarea
            ref={ref}
            // name={editName || file?.editName}
            name={isFileName}
            // name={file?.name}
            handleChangeName={(value) => {
              setIsFileName(value);
              if (value !== file?.name) {
                onRename(value);
              }
            }}
            onClick={(e) => {
              e.stopPropagation();
              ref?.current?.focus();
              setIsFocused(true);
            }}
            onBlur={() => {
              setIsEditing(false);
              setIsFocused(false);
            }}
            onMouseLeave={() => {
              if (!isFocused) {
                setIsEditing(false);
              }
            }}
            className={cx('file-attachment__title', {
              'file-attachment__title--cursor-text': canDelete,
            })}
            defaultHeight={file!.name.length < 50 ? 35 : 40}
            placeholder="Назва файлу"
          />
        ) : (
          <Heading
            onClick={(e) => {
              e.stopPropagation();
              if (canDelete) {
                if (file?.url) {
                  setIsEditing(true);
                  setTimeout(() => {
                    ref?.current?.focus();
                  }, 0);
                }
                // } else if (file?.url) {
                //   onOpen(file);
              }
            }}
            className={cx('file-attachment__title', {
              'file-attachment__title--cursor-text': canDelete,
            })}
            onMouseEnter={() => setIsEditing(true)}
            size="sm"
          >
            {/* {editName ? `${editName}` : `${file?.name}`} */}
            {/* {file?.name} */}
            {isFileName}
            {/* {file?.extension && editName && (
              <span style={{ display: 'inline-block' }}>
                .{file?.extension}
              </span>
            )} */}
          </Heading>
        )}
        <Text as="span" size="sm" className="file-attachment__text">
          {!notSupported ? (
            convertBytesToKbOrMbOrGb(file?.size)
          ) : (
            <span style={{ color: '#F1556D' }}>
              {largeFile
                ? 'Файл занадто великий'
                : 'Даний тип файла не підтримується. Спробуйте запакувати файли в архів.'}
            </span>
          )}
          {file.url && (
            <a
              href={file.url}
              download={file.name}
              rel="noopener noreferrer"
              target="_blank"
              style={{ display: 'flex' }}
            >
              <IconDownload className="file-attachment__icon file-attachment__icon--download" />
            </a>
          )}
          {canDelete && file.url && (
            <IconTrash
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
              className="file-attachment__delete"
            />
          )}
        </Text>
        {!file.url && (
          <Text as="span" size="sm" className="file-attachment__text">
            {!!progress && (
              <div className="file-attachment__progress">
                <span>{`${progress}%`}</span>
                {progress === 100 ? (
                  <IconCheck />
                ) : (
                  <IconCancel
                    onClick={onCancel}
                    className="file-attachment__icon file-attachment__icon--cancel"
                  />
                )}
                <div className="file-attachment__progress-wrap">
                  <div
                    className="file-attachment__progress-bar"
                    style={{ '--posX': `${progress}%` } as any}
                  />
                </div>
              </div>
            )}
          </Text>
        )}
      </div>
    </div>
  );
};
