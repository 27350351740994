import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import Tooltip from 'rc-tooltip';
import dayjs from 'dayjs';
import 'dayjs/locale/uk';

import Text from '../../../components/UI/Text/Text';

import { IMessage } from '../../../common/types';

import {
  convertBytesToKbOrMbOrGb,
  isTheSameAuthor as isTheSame,
} from '../../../helpers/common';

import './Message.scss';
import { useThemeToggler } from '../../../context/ThemeContext';

import { ReactComponent as IconDownload } from '../../../media/icons/download.svg';
import { ReactComponent as IconInfo } from '../../../media/icons/info_circle_outline.svg';
import FileExtensions from '../../../components/UI/FileExtensions/FileExtensions';

import { imgExtensions, videoExtensions } from '../../../helpers/constant';
import User from '../../../components/UsersList/User/User';
import {
  GLOBAL_ROLES_COLORS,
  PROJECT_ROLES_COLORS,
  MESSAGE_DATA_FULL_FORMAT,
  TIME_FORMAT_SHORT,
  DAY_FULL_MONTH,
} from '../../../common/constant';

export interface MessageProps {
  message: IMessage;
  isTheSameAuthor?: boolean;
  className?: string;
  bodyClassName?: string;
  setActiveMessage?: any;
  dataMessageId?: string;
  dataEmoji?: string;
  isTheSameDay?: boolean | any;
  isSystem?: boolean;
  isFileDeleted?: boolean;
  bgColor?: string;
  parentBgColor?: string;
  onOpen?: () => void;
  onScrollTo?: () => void;
  onRecoverMessage?: (message: IMessage) => void;
  onSendEmoji?: (emoji: string, messageId: number) => void;
}

dayjs.locale('uk');

export const domenZones = [
  '.com',
  '.org',
  '.net',
  '.int',
  '.edu',
  '.gov',
  '.mil',
  '.info',
  '.biz',
  '.name',
  '.pro',
  '.aero',
  '.coop',
  '.museum',
  '.mobi',
  '.jobs',
  '.cat',
  '.tel',
  '.travel',
  '.asia',
  '.xxx',
  '.post',
  '.top',
  '.xyz',
  '.site',
  '.online',
  '.tech',
  '.store',
  '.space',
  '.website',
  '.icu',
  '.app',
  '.art',
  '.blog',
  '.dev',
  '.design',
  '.digital',
  '.cloud',
  '.club',
  '.life',
  '.ltd',
  '.email',
  '.shop',
  '.photo',
  '.expert',
  '.guru',
  '.law',
  '.news',
  '.tv',
  '.audio',
  '.love',
  '.studio',
  '.world',
  '.network',
  '.solutions',
  '.systems',
  '.ac',
  '.ad',
  '.ae',
  '.af',
  '.ag',
  '.ai',
  '.al',
  '.am',
  '.an',
  '.ao',
  '.aq',
  '.ar',
  '.as',
  '.at',
  '.au',
  '.aw',
  '.ax',
  '.az',
  '.ba',
  '.bb',
  '.bd',
  '.be',
  '.bf',
  '.bg',
  '.bh',
  '.bi',
  '.bj',
  '.bm',
  '.bn',
  '.bo',
  '.br',
  '.bs',
  '.bt',
  '.bv',
  '.bw',
  '.by',
  '.bz',
  '.ca',
  '.cc',
  '.cd',
  '.cf',
  '.cg',
  '.ch',
  '.ci',
  '.ck',
  '.cl',
  '.cm',
  '.cn',
  '.co',
  '.cr',
  '.cu',
  '.cv',
  '.cw',
  '.cx',
  '.cy',
  '.cz',
  '.de',
  '.dj',
  '.dk',
  '.dm',
  '.do',
  '.dz',
  '.ec',
  '.ee',
  '.eg',
  '.er',
  '.es',
  '.et',
  '.eu',
  '.fi',
  '.fj',
  '.fk',
  '.fm',
  '.fo',
  '.fr',
  '.ga',
  '.gb',
  '.gd',
  '.ge',
  '.gf',
  '.gg',
  '.gh',
  '.gi',
  '.gl',
  '.gm',
  '.gn',
  '.gp',
  '.gq',
  '.gr',
  '.gs',
  '.gt',
  '.gu',
  '.gw',
  '.gy',
  '.hk',
  '.hm',
  '.hn',
  '.hr',
  '.ht',
  '.hu',
  '.id',
  '.ie',
  '.il',
  '.im',
  '.in',
  '.io',
  '.iq',
  '.ir',
  '.is',
  '.it',
  '.je',
  '.jm',
  '.jo',
  '.jp',
  '.ke',
  '.kg',
  '.kh',
  '.ki',
  '.km',
  '.kn',
  '.kp',
  '.kr',
  '.kw',
  '.ky',
  '.kz',
  '.la',
  '.lb',
  '.lc',
  '.li',
  '.lk',
  '.lr',
  '.ls',
  '.lt',
  '.lu',
  '.lv',
  '.ly',
  '.ma',
  '.mc',
  '.md',
  '.me',
  '.mg',
  '.mh',
  '.mk',
  '.ml',
  '.mm',
  '.mn',
  '.mo',
  '.mp',
  '.mq',
  '.mr',
  '.ms',
  '.mt',
  '.mu',
  '.mv',
  '.mw',
  '.mx',
  '.my',
  '.mz',
  '.na',
  '.nc',
  '.ne',
  '.nf',
  '.ng',
  '.ni',
  '.nl',
  '.no',
  '.np',
  '.nr',
  '.nu',
  '.nz',
  '.om',
  '.pa',
  '.pe',
  '.pf',
  '.pg',
  '.ph',
  '.pk',
  '.pl',
  '.pm',
  '.pn',
  '.pr',
  '.ps',
  '.pt',
  '.pw',
  '.py',
  '.qa',
  '.re',
  '.ro',
  '.rs',
  '.ru',
  '.rw',
  '.sa',
  '.sb',
  '.sc',
  '.sd',
  '.se',
  '.sg',
  '.sh',
  '.si',
  '.sj',
  '.sk',
  '.sl',
  '.sm',
  '.sn',
  '.so',
  '.sr',
  '.ss',
  '.st',
  '.su',
  '.sv',
  '.sx',
  '.sy',
  '.sz',
  '.tc',
  '.td',
  '.tf',
  '.tg',
  '.th',
  '.tj',
  '.tk',
  '.tl',
  '.tm',
  '.tn',
  '.to',
  '.tr',
  '.tt',
  '.tv',
  '.tz',
  '.ua',
  '.ug',
  '.uk',
  '.us',
  '.uy',
  '.uz',
  '.va',
  '.vc',
  '.ve',
  '.vg',
  '.vi',
  '.vn',
  '.vu',
  '.wf',
  '.ws',
  '.ye',
  '.za',
  '.zm',
  '.zw',
  // Internationalized domain extensions (IDNs)
  '.рф',
  '.中国',
  '.みんな',
  '.한국',
  '.বাংলা',
  '.бел',
  '.срб',
  '.امارات',
  '.ايران',
  '.فلسطين',
  '.ไทย',
  '.كوم',
  '.台灣',
  '.каз',
  '.भारत',
  '.مصر',
  '.العراق',
  '.پاکستان',
  '.السعودية',
  '.中国',
  '.қаз',
  '.اردو',
  '.такси',
  '.грузия',
  '.рф',
  '.ру',
  '.школа',
  '.дети',
  '.онлайн',
  '.москва',
  '.уз',
  '.рф',
  '.кыргызстан',
  '.қазақстан',
  '.сайт',
  '.гвинея',
  '.ευ',
  '.հայ',
  '.संगठऩ',
  '.프로그래밍',
  '.католик',
  '.вулкан',
  '.қырғызстан',
  '.крадій',
  '.рф',
  '.新加坡',
  '.ナビ',
  '.шығару',
  '.커뮤니티',
  '.المغرب',
  '.عيد',
  '.сеть',
  '.მუზეუმი',
  '.酒',
  '.الجزائر',
  '.uk.com',
  '.лнр',
  '.цр',
  '.бг',
  '.ぽく',
  '.ред',
  '.مقابل',
  '.سوريا',
  '.جاء',
  '.воен',
  '.مملكة',
  '.йога',
];

export const regexForEMail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export function parseText(input: string): string {
  const parser = new DOMParser();
  const doc = parser.parseFromString(input, 'text/html');
  const nodes = doc.body.childNodes;
  let resultHTML = '';

  nodes.forEach((node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/g;
      const linkRegex =
        /(@)?((http|https|ftp|sftp|xps):\/\/)?((([a-zA-Z0-9\u00a1-\uffff-]+\.)+))?([a-zA-Z0-9\u00a1-\uffff-]+)\.([a-zA-Z\u00a1-\uffff]{2,})(\.[a-zA-Z\u00a1-\uffff]{2,})?(\/[^\s?#]*)?(\?[^\s#]*)?(#[^\s]*)?/g;

      let content = node.textContent || '';

      content = content.replace(linkRegex, (link, offset) => {
        // Проверяем, если перед ссылкой непосредственно есть символ "@"
        if (link.startsWith('@')) {
          // Если перед ссылкой стоит "@", не обрабатываем как ссылку
          return link;
        }

        // В противном случае, добавляем ссылку
        const protocol = link.startsWith('http') ? '' : 'http://';
        return `<a href="${protocol}${link}" target="_blank" rel="noopener noreferrer">${link}</a>`;
      });

      content = content.replace(
        emailRegex,
        (email) => `<a href="mailto:${email}">${email}</a>`
      );

      resultHTML += content;
    } else {
      resultHTML += (node as HTMLElement).outerHTML;
    }
  });

  return resultHTML;
}

export const regexForLinkWithQuery =
  /((http|https|ftp|sftp|xps):\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(\/[^\s?#]*)?(\?[^\s#]*)?(#[^\s]*)?/g;

export const Message: React.FC<MessageProps> = ({
  message,
  className,
  bodyClassName,
  setActiveMessage,
  isTheSameAuthor,
  isSystem,
  dataMessageId,
  dataEmoji,
  isTheSameDay,
  isFileDeleted,
  bgColor,
  parentBgColor,
  onOpen = () => {},
  onScrollTo = () => {},
  onRecoverMessage = () => {},
  onSendEmoji = () => {},
}) => {
  const { theme } = useThemeToggler();
  const globalUser = useSelector((state: any) => state.auth);

  const messageDate = message.dateCreate || message?.date;

  const permissionColor = bgColor;
  // let textLinkEdited = message?.text.replaceAll('\n', '<br>');
  let textLinkEdited = message?.text;

  if (
    typeof message?.text === 'string' &&
    message?.text.includes('<a href="')
  ) {
    textLinkEdited = textLinkEdited.replace(
      /<a /g,
      '<a target="_blank" rel="noopener noreferrer" '
    );
  }

  // textLinkEdited = findLinksWithoutWrappingTags(textLinkEdited);
  // textLinkEdited = formatTextWithLinksAndEmails(textLinkEdited);
  // below is an actual function
  // textLinkEdited = !isSystem
  //   ? parserTest(textLinkEdited, message?.id)
  //   : textLinkEdited;
  textLinkEdited = !isSystem ? parseText(textLinkEdited) : textLinkEdited;

  const diffTime = Date.now() - message.dateCreate / (1000 * 60 * 60 * 24);

  // textLinkEdited.replace(/\n/g, '<br>');

  const messageBodyClassNames = cx(
    'message__body',
    {
      'message__body--file': message?.file,
      'message__body--system': isSystem,
      'message__body--error': message?.withError,
    },
    bodyClassName
  );

  const messageClassNames = cx(
    'message',
    {
      'message--deleted': message?.deleted,
      'message--from-the-same-autor': isTheSameAuthor && !isSystem,
      'message--system': isSystem,
      [`message--${theme}`]: theme,
    },
    className
  );

  const userStyles = !message?.user?.avatar?.url
    ? {
        backgroundColor: permissionColor,
      }
    : {};

  return (
    <>
      {!isTheSameDay && diffTime > 0 && (
        <div className="message__date-separator">
          <Text size="sm" className="message__date">
            {dayjs.unix(message.dateCreate).format(DAY_FULL_MONTH)}
          </Text>
        </div>
      )}
      <div
        data-tid={message?.tId}
        className={messageClassNames}
        data-message-id={dataMessageId}
        data-emoji={dataEmoji}
        style={
          {
            '--color': permissionColor,
          } as React.CSSProperties
        }
      >
        {!isSystem ? (
          <div className="message__header">
            {!isTheSameAuthor ? (
              <User
                className="notification-item__avatar"
                isOnline
                user={message?.user}
                size="md"
                backgroundColor={bgColor}
                style={userStyles}
              />
            ) : !isTheSameDay || !isTheSameAuthor ? (
              <User
                className="notification-item__avatar"
                isOnline
                user={message?.user}
                size="md"
                backgroundColor={bgColor}
                style={userStyles}
              />
            ) : null}
          </div>
        ) : (
          <IconInfo className="message__system-avatar" />
        )}
        <div className="message__inner" id={`message-${message.id}`}>
          <div className={messageBodyClassNames}>
            {message?.parentMessage && (
              <div className="message__parent">
                {!isTheSameAuthor && isTheSameDay && (
                  <Text
                    size="sm"
                    className="message__parent-author"
                    style={{
                      color: permissionColor,
                      marginBottom: '7px',
                    }}
                  >
                    {`${message?.user.firstName} ${message?.user.lastName}`}{' '}
                  </Text>
                )}
                <div
                  className={cx('message__parent-inner', {
                    'message__parent-inner--text':
                      !message?.parentMessage?.file,
                  })}
                  style={{
                    borderLeftColor: parentBgColor
                      ? parentBgColor
                      : permissionColor,
                  }}
                >
                  {message?.parentMessage?.file ? (
                    imgExtensions.includes(
                      message?.parentMessage?.file?.extension
                    ) ? (
                      <img
                        src={
                          message?.parentMessage?.file?.thumb ||
                          message?.parentMessage?.file?.url
                        }
                        alt={message?.parentMessage?.file?.url}
                        className="message__parent-photo"
                      />
                    ) : (
                      <FileExtensions
                        className="message__parent-file-label"
                        type={message?.parentMessage?.file?.extension}
                      />
                    )
                  ) : null}
                  <div className="message__parent-header">
                    <div>
                      <Text
                        size="sm"
                        className="message__parent-author"
                        style={{
                          color: parentBgColor
                            ? parentBgColor
                            : permissionColor,
                        }}
                      >
                        {`${message?.parentMessage?.user.firstName} ${message?.parentMessage?.user.lastName}`}
                        {message?.parentMessage?.citeText && (
                          <span className="message__parent-quote">&#8221;</span>
                        )}
                      </Text>
                    </div>
                    <div
                      className="message__text message__text--parent"
                      onClick={onScrollTo}
                    >
                      {!message?.parentMessage?.file
                        ? message?.parentMessage?.citeText ||
                          message?.parentMessage?.text
                        : imgExtensions.includes(
                            message?.parentMessage?.file?.extension
                          )
                        ? 'Фото'
                        : videoExtensions.includes(
                            message?.parentMessage?.file?.extension
                          )
                        ? 'Відео'
                        : 'Файл'}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {(!isTheSameAuthor ||
              (!isTheSameDay && !message.parentMessage)) && (
              <div className="message__top">
                {!message?.parentMessage && !isSystem && (
                  <Text className="message__author" size="sm">
                    {`${message?.user.firstName} ${message?.user.lastName}`}
                  </Text>
                )}
              </div>
            )}
            <div
              className={cx('message__text message__text--inner', {
                'message__text--file': message?.file,
              })}
            >
              {message?.deleted &&
              isTheSame(globalUser.user.id, message.user.id) ? (
                <div
                  className={
                    theme === 'dark'
                      ? 'message__deleted-dark'
                      : 'message__deleted-content'
                  }
                >
                  Повідомлення видалене.
                  <span
                    onClick={() => onRecoverMessage(message)}
                    className="message__restore-btn"
                  >
                    Повернути
                  </span>
                </div>
              ) : (
                <>
                  {isSystem ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: textLinkEdited,
                      }}
                    />
                  ) : (
                    // !message?.file && textLinkEdited
                    !message?.file &&
                    textLinkEdited && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: textLinkEdited,
                        }}
                      />
                    )
                  )}
                  {message?.file && !isFileDeleted && (
                    <div className="message__file">
                      {message?.file.type === 'image' ? (
                        <img
                          onClick={onOpen}
                          className="message__file-img"
                          src={message?.file.thumb}
                          alt={message?.name}
                          style={{
                            cursor: 'pointer',
                            backgroundColor: message?.file.color,
                          }}
                        />
                      ) : (
                        <FileExtensions
                          className="message__file-label"
                          type={message?.file?.extension}
                        />
                      )}
                      <div className="message__file-details">
                        <div className="message__file-name">
                          {message?.file.name}
                        </div>
                        <div className="message__file-size">
                          <a
                            className="message__file-button"
                            href={message?.file.url}
                            target="_blank"
                            download={message?.file.name}
                            rel="noreferrer"
                          >
                            <IconDownload className="message__file-icon" />
                          </a>
                          {convertBytesToKbOrMbOrGb(message?.file.size)}
                        </div>
                      </div>
                      <Text size="sm" className="message__date">
                        {!message?.parentMessage && message?.edited && (
                          <span className="message__edited">змінено </span>
                        )}
                      </Text>
                    </div>
                  )}
                </>
              )}
            </div>
            {message && (
              <div className="message__bottom">
                {message?.emojiList?.length && (
                  <div className="message__reactions">
                    {message?.emojiList?.map((emoji: any) => (
                      <div
                        key={Date.now() + Math.random()}
                        className="message__reaction"
                        onClick={() => onSendEmoji(emoji.emoji, message.id)}
                      >
                        <span className="message__emoji">{emoji.emoji}</span>
                        {emoji.users?.map(
                          (user: any): JSX.Element => (
                            <User
                              // key={Date.now() * Math.random()}
                              key={user.id}
                              user={user}
                              size="xs"
                              className="message__reaction-user"
                              backgroundColor={
                                user.groupId === 2
                                  ? PROJECT_ROLES_COLORS[user.roleId]
                                  : GLOBAL_ROLES_COLORS[user.groupId]
                              }
                            />
                          )
                        )}
                      </div>
                    ))}
                  </div>
                )}
                {message?.text && (
                  <Text size="sm" className="message__date">
                    {message?.edited && (
                      <span className="message__edited">змінено </span>
                    )}
                    <Tooltip
                      placement="top"
                      overlayStyle={{ transform: 'translateX(60px)' }}
                      trigger={['click']}
                      overlayClassName="message__tooltip-overlay"
                      overlay={
                        <div className="message__tooltip">
                          <div>
                            {dayjs
                              .unix(message.dateCreate)
                              .format(MESSAGE_DATA_FULL_FORMAT)}
                          </div>
                          {message?.dateEdit && (
                            <div>
                              Змінено:{' '}
                              {dayjs
                                .unix(messageDate)
                                .format(MESSAGE_DATA_FULL_FORMAT)}
                            </div>
                          )}
                        </div>
                      }
                    >
                      <span>
                        {dayjs.unix(messageDate).format(TIME_FORMAT_SHORT)}
                      </span>
                    </Tooltip>
                  </Text>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
