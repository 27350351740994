import React, { memo } from 'react';
import { useParams } from 'react-router-dom';

import SettingsPanel from '../../../layout/SettingsPanel/SettingsPanel';
import TaskList from '../../../layout/TaskList/TaskList';
import Task from '../../../forms/Task/Task';

const MainPage = (): JSX.Element => (
  <>
    <SettingsPanel />
    <TaskList />
    <TaskByCondition />
  </>
);

const TaskByCondition = memo((): JSX.Element | null => {
  const { slugTask } = useParams();
  if (slugTask) {
    return <Task />;
  }
  return null;
});

export default MainPage;
