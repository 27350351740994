import { createAsyncThunk } from '@reduxjs/toolkit';
import billingService from '../../services/billing.service';

export const fetchBalance = createAsyncThunk('billing/balance', async () => {
  try {
    return await billingService.getBalance();
  } catch (error) {
    return error;
  }
});

export const fetchBalanceStatistic = createAsyncThunk(
  'billing/balanceStatistic',
  async () => {
    try {
      return await billingService.getBalanceStatistic();
    } catch (error) {
      return error;
    }
  }
);

export const fetchBalanceTransactions = createAsyncThunk(
  'billing/balanceTransactions',
  async ({ listType, lastId }: any) => {
    try {
      return await billingService.getBalanceTransactions({ listType, lastId });
    } catch (error) {
      return error;
    }
  }
);

export const getSingleProjectBalanceTransaction = createAsyncThunk(
  'billing/singleProjectBalanceTransaction',
  async (data: any) => {
    try {
      return await billingService.getSingleProjectBalanceTransaction(data);
    } catch (error) {
      return error;
    }
  }
);

export const getSingleBalanceTransaction = createAsyncThunk(
  'billing/singleBalanceTransaction',
  async (transactionId: string) => {
    try {
      return await billingService.getSingleBalanceTransaction(transactionId);
    } catch (error) {
      return error;
    }
  }
);

export const balanceRequest = createAsyncThunk(
  'billing/balanceRequest',
  async (data: any) => {
    try {
      return await billingService.balanceRequest(data);
    } catch (error) {
      return error;
    }
  }
);

export const balanceToProject = createAsyncThunk(
  'billing/balanceToProject',
  async (data: any) => {
    try {
      return await billingService.balanceToProject(data);
    } catch (error) {
      return error;
    }
  }
);

export const refillBalance = createAsyncThunk(
  'billing/refillBalance',
  async (data: any) => {
    try {
      return await billingService.refillBalance(data);
    } catch (error) {
      return error;
    }
  }
);

export const fetchProjectBalance = createAsyncThunk(
  'billing/projectBalance',
  async (id: string) => {
    try {
      return await billingService.getProjectBalance(id);
    } catch (error) {
      return error;
    }
  }
);

export const fetchProjectBalanceTransactions = createAsyncThunk(
  'billing/projectBalanceTransactions',
  async ({ slug, lastId }: { slug: string; lastId: any }) => {
    try {
      return await billingService.getProjectBalanceTransactions(slug, lastId);
    } catch (error) {
      return error;
    }
  }
);

export const fetchProjectBalanceStatistic = createAsyncThunk(
  'billing/projectBalanceStatistic',
  async (slug: string) => {
    try {
      return await billingService.getProectBalanceStatistic(slug);
    } catch (error) {
      return error;
    }
  }
);

export const moveBonusesToBalance = createAsyncThunk(
  'billing/moveBonusesToBalance',
  async ({ sum }: { sum: number }) => {
    try {
      return await billingService.moveBonusesToBalance({ sum });
    } catch (error) {
      return error;
    }
  }
);

export const fetchBonusesTransactions = createAsyncThunk(
  'billing/bonusesTransactions',
  async () => {
    try {
      return await billingService.getBonusesTransactions();
    } catch (error) {
      return error;
    }
  }
);

export const fetchBillingBonuses = createAsyncThunk(
  'billing/info',
  async () => {
    try {
      return await billingService.getBonuses();
    } catch (error) {
      return error;
    }
  }
);

export const fetchBonusesStatistic = createAsyncThunk(
  'billing/bonusesStatistic',
  async () => {
    try {
      return await billingService.getBonusesStatistic();
    } catch (error) {
      return error;
    }
  }
);
