import React, { forwardRef, memo } from 'react';
import Select from 'react-select';
import cx from 'classnames';

import { useThemeToggler } from '../../../context/ThemeContext';

import './CustomSelect.scss';

const CustomSelect = forwardRef(
  (
    {
      options,
      placeholder,
      className,
      classNamePrefix,
      onchange,
      menuIsOpen,
      isSearchable = false,
      ...rest
    }: any,
    ref: any
  ): JSX.Element => {
    const { theme } = useThemeToggler();

    return (
      <Select
        menuIsOpen={menuIsOpen}
        // key={
        //   options.find((item: any) => item.value === rest.value)?.value || ''
        // }
        onChange={onchange}
        controlRef={ref}
        isSearchable={isSearchable}
        options={options.map((item: any) => ({
          label: item.label,
          value: item.value,
          id: item.value,
          email: item.email,
          text: item.text,
          ...item,
        }))}
        placeholder={placeholder}
        className={cx(
          'custom-select',
          {
            [`custom-select--${theme}`]: theme,
          },
          className
        )}
        onMenuOpen={() => {
          setTimeout(() => {
            const selectedOptionElement = document.querySelector(
              '.custom-select__option--is-selected'
            );
            const d = document.querySelector(
              '.custom-select__option--is-focused'
            );
            d?.classList.add('delete');
            selectedOptionElement?.scrollIntoView({
              block: 'nearest',
            });
            d?.classList.remove('delete');
          });
        }}
        classNamePrefix={cx('custom-select', classNamePrefix)}
        {...rest}
      />
      // <select
      //   onChange={onchange}
      //   ref={ref}
      //   // options={options.map((item: any) => ({
      //   //   label: item.label,
      //   //   value: item.value,
      //   //   id: item.value,
      //   //   email: item.email,
      //   //   text: item.text,
      //   //   ...item,
      //   // }))}
      //   placeholder={placeholder}
      //   className={cx(
      //     'custom-select',
      //     {
      //       [`custom-select--${theme}`]: theme,
      //     },
      //     className
      //   )}
      // >
      //   {options.map((item: any) => (
      //     <option key={item.value} value={item.value}>
      //       {item.label}
      //     </option>
      //   ))}
      // </select>
    );
  }
);

export default memo(CustomSelect);
