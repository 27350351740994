import React, { useMemo, useState, useCallback, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import Tooltip from 'rc-tooltip';
import dayjs from 'dayjs';

import { useThemeToggler } from '../../../context/ThemeContext';

import RichTextEditor from '../../../components/RichTextEditor/RichTextEditor';
import CustomSelect from '../../../components/UI/CustomSelect/CustomSelect';
import Heading from '../../../components/UI/Heading/Heading';
import Modal from '../../../components/Modal/Modal';
import Spinner from '../../../components/UI/Spinner/Spinner';

import { parseText } from '../../../layout/Chat/Message/Message';

import {
  DATE_FORMAT_FULL,
  MESSAGE_DATA_FULL_FORMAT,
  PROJECT_ROLES,
  STATUS_DRAFT,
  STATUS_NEW,
} from '../../../common/constant';
import { capitializeFirstLetter } from '../../../helpers/common';

import { AppDispatch } from '../../../store';
import { getTaskHistory } from '../../../store/tasks/thunk';

import { ReactComponent as IconPaper } from '../../../media/icons/paper-1.svg';

import './DescriptionTask.scss';
import { checkPermission } from '../../../hooks/checkPermission';

const newHtmlParser = (srt: string): any => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(srt, 'text/html');

  let allParentNodes: any = Array.from(doc.body.childNodes);

  for (let i = 0, len = allParentNodes.length; i < len; i++) {
    if (allParentNodes[i].nodeType === Node.ELEMENT_NODE) {
      allParentNodes[i].innerHTML = parseText(
        allParentNodes[i].innerHTML.replaceAll('\n', '') || ''
      );
    } else if (allParentNodes[i].nodeType === Node.TEXT_NODE) {
      allParentNodes[i].textContent = parseText(
        allParentNodes[i].textContent || ''
      );
    }
  }
  return allParentNodes.map((node: any) => node.outerHTML).join('');
};

interface IDescriptionTaskProps {
  onChangeDescription: (value: string) => void;
  taskFiles: any;
  inputRef: any;
  archiveTasks: any;
}

const DescriptionTask = ({
  onChangeDescription,
  taskFiles,
  inputRef,
  archiveTasks,
}: IDescriptionTaskProps): JSX.Element => {
  const { theme } = useThemeToggler();
  const { taskInfo, taskText, taskHistory, textLoaded } = useSelector(
    (state: { taskData: any }) => state.taskData
  );
  const { userInfo } = useSelector(
    (state: { userData: any }) => state.userData
  );
  const [history, setHistory] = useState<any>({});
  const [isActive, setIsActive] = useState<boolean>(false);
  const dispatch: AppDispatch = useDispatch();
  const params = useParams();

  const isSalesManager = userInfo.roleId === PROJECT_ROLES.salesManager;

  useLayoutEffect(() => {
    if (
      taskInfo?.statusId === STATUS_NEW ||
      taskInfo?.statusId === STATUS_DRAFT
    ) {
      dispatch(getTaskHistory(params.slugTask));
    }
  }, []);

  const historyHandler = useCallback(
    (e: any): void => {
      setHistory({
        id: e.id,
        dateCreate: e.value,
        text: e.text,
      });
    },
    [history]
  );

  const historyList = useMemo(
    () =>
      taskHistory.list.map((item: any) => ({
        label: dayjs.unix(item.dateCreate).format(DATE_FORMAT_FULL),
        value: item.id,
        id: item.id,
        text: item.text,
        date: item.dateCreate,
      })),
    [taskHistory, history, taskText]
  );

  const archiveTask = useMemo(
    () =>
      archiveTasks.length
        ? archiveTasks?.find((item: any) => item.slug === params.slugTask)
        : null,
    [archiveTasks, taskInfo]
  );

  const textReplaced = taskText.replaceAll('\n', '') || '';

  // const taskTextUpdated = !taskText.includes('<img')
  //   ? findLinkWithoutHttp(textReplaced)
  //   : textReplaced;

  function unwrapHighLevelTags(input: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(input, 'text/html');

    // Process all high-level elements in the body
    const unwrappedContent = Array.from(doc.body.childNodes)
      .map((node) => {
        if (node.nodeType === Node.ELEMENT_NODE) {
          // For element nodes, return the inner content
          return (node as HTMLElement).innerHTML;
        } else if (node.nodeType === Node.TEXT_NODE) {
          // For text nodes, return the text content
          return node.textContent || '';
        }
        return '';
      })
      .join('');

    return unwrappedContent;
  }

  let taskTextUpdated = newHtmlParser(taskText);

  return (
    <div
      className={cx('description-task', {
        [`description-task--${theme}`]: theme,
      })}
    >
      <div className="description-task__header">
        <p className="description-task__item description-task__name">
          <span className="description-task__id">
            #{archiveTask ? archiveTask?.id : taskInfo?.id}
          </span>
          <span className="description-task__id">
            @{taskInfo?.project.name}
          </span>
          {!archiveTask && (
            <>
              <span className="ci ci-user" />
              {`${
                taskInfo?.user?.firstName &&
                capitializeFirstLetter(taskInfo?.user.firstName)
              } ${
                taskInfo?.user?.lastName &&
                capitializeFirstLetter(taskInfo?.user?.lastName)
              }`}
            </>
          )}
        </p>

        <p className="description-task__item" onClick={() => setHistory({})}>
          <Tooltip
            placement="top"
            overlay={
              <span>
                Створено:{' '}
                <div>
                  {dayjs
                    .unix(taskInfo?.dateDraft)
                    .format(MESSAGE_DATA_FULL_FORMAT)}
                </div>
              </span>
            }
          >
            <span className="ci ci-file_blank_outline description-task__icon" />
          </Tooltip>
        </p>
        {taskInfo?.dateCreate && (
          <p className="description-task__item">
            <Tooltip
              placement="top"
              overlay={
                <span>
                  Опубліковано:{' '}
                  <div>
                    {dayjs
                      .unix(taskInfo?.dateCreate)
                      .format(MESSAGE_DATA_FULL_FORMAT)}
                  </div>
                </span>
              }
            >
              <span className="ci ci-file_blank_fill description-task__icon" />
            </Tooltip>
          </p>
        )}
        {!!historyList?.length && (
          <Tooltip
            align={{ useCssRight: true, offset: [13, -5] }}
            placement="topRight"
            overlay={<span style={{ width: '100px' }}>Дивитись архів</span>}
          >
            <IconPaper
              className="description-task__archive-icon"
              onClick={() => setIsActive(true)}
            />
          </Tooltip>
        )}
      </div>
      {typeof taskText === 'string' && !taskText && !textLoaded && (
        <div
          className="screen-loader-wrapper"
          style={{
            height: '120px',
            marginTop: '50px',
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, 20%)',
          }}
        >
          <Spinner size="lg" className="task-loading" />
        </div>
      )}
      {(taskInfo?.statusId === STATUS_DRAFT ||
        taskInfo?.statusId === STATUS_NEW) &&
      !isSalesManager ? (
        // {taskInfo?.statusId === STATUS_DRAFT ||
        // taskInfo?.statusId === STATUS_NEW ? (
        <div>
          <RichTextEditor
            theme={theme}
            content={taskText}
            editorName="description"
            onChangeDescription={onChangeDescription}
          />
        </div>
      ) : (
        taskText && (
          // <div>11111</div>
          <div
            className={cx('description-task__content ql-editor', {})}
            dangerouslySetInnerHTML={{
              __html: taskTextUpdated,
            }}
          />
        )
      )}
      {checkPermission('STAFF') &&
        (taskInfo?.textStatus === 'checkTest' ||
          taskInfo?.textStatus === 'checkProd') && (
          <span className="description-task__badge">Очікує підтвердження</span>
        )}
      {(taskInfo?.statusId === STATUS_NEW ||
        taskInfo?.statusId === STATUS_DRAFT ||
        !!taskFiles?.length) && (
        <div className="description-task__files">
          <Heading size="sm" className="description-task__title">
            Вкладення
            {(taskInfo?.statusId === STATUS_NEW ||
              taskInfo?.statusId === STATUS_DRAFT) && (
              <Tooltip
                placement="top"
                overlay={
                  <>
                    <div>Працює вставлення з буфера</div>
                    <div>та перетаскування файлів</div>
                  </>
                }
              >
                <button
                  className="task-list__btn"
                  onClick={() => inputRef?.current.click()}
                >
                  <span className="ci ci-plus" />
                </button>
              </Tooltip>
            )}
          </Heading>
          <hr
            className={cx('task__line', {
              'task__line--mb': !taskFiles?.length,
              'task__line--mb-10': taskFiles?.length,
            })}
          />
        </div>
      )}
      {historyList && (
        <Modal
          size="md"
          title="Архів версій"
          isActive={isActive}
          handleClose={() => {
            setIsActive(false);
            setHistory({});
          }}
          leftButton={false}
          modalClassName="block-archive"
        >
          <div className="block-archive__inner">
            <div className="block-archive__top">
              <CustomSelect
                onChange={historyHandler}
                className="description-task__archive"
                options={historyList}
                isSearchable={false}
                placeholder={dayjs
                  .unix(historyList[0]?.date)
                  .format('DD.MM.YYYY HH:mm:ss')}
                defaultValue={false}
                resetValue={history.text}
              />
            </div>
            <div className="block-archive__bottom ql-editor">
              {history.text && (
                <div
                  className="description-task__content"
                  dangerouslySetInnerHTML={{
                    __html: parseText(
                      unwrapHighLevelTags(
                        history.text.replaceAll('\n', '') || ''
                      )
                    ),
                  }}
                />
              )}
              {historyList[0] && !history.text && (
                <div
                  className="description-task__content"
                  dangerouslySetInnerHTML={{
                    __html: parseText(
                      unwrapHighLevelTags(
                        historyList[0].text.replaceAll('\n', '') || ''
                      )
                    ),
                  }}
                />
              )}
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default DescriptionTask;
