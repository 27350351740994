import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import ProjectsPage from './ProjectsPage/ProjectsPage';
import MainPage from './MainPage/MainPage';
import { checkPermission } from '../../hooks/checkPermission';

const Dashboard = (): JSX.Element => {
  const isClient = checkPermission('CLIENT');

  return isClient ? <ProjectsPage /> : <MainPage />;
};

export default Dashboard;
