import React, { useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import User from '../../components/UsersList/User/User';
import { TUserData } from '../../layout/ProjectList/types';
import Button from '../../components/Button/Button';
import Input from '../../components/UI/Input/Input';
import CustomSelect from '../../components/UI/CustomSelect/CustomSelect';

import { AppDispatch } from '../../store';
import {
  addUserToProject,
  changeUserRole,
  deleteUserFromProject,
} from '../../store/projects/thunk';
import { useThemeToggler } from '../../context/ThemeContext';
import { PROJECT_ROLES, PROJECT_ROLES_COLORS } from '../../common/constant';
import { emailValidationString } from '../../helpers/constant';

import './AddParticipants.scss';

interface IAddParticipantsProps {
  projectId: number;
  usersArray: TUserData[];
  slug: string;
}

const AddParticipants = ({
  slug,
  usersArray,
}: IAddParticipantsProps): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();
  const {
    register,
    handleSubmit,
    clearErrors,
    getValues,
    setValue,
    formState: { errors },
    setError,
  } = useForm<{ email: string }>({
    mode: 'onChange',
  });
  const { roleList, userInfo, onlineUsers } = useSelector(
    (state: { userData: any }) => state.userData
  );
  const { theme } = useThemeToggler();
  const onSubmit: SubmitHandler<{ email: string }> = (data: {
    email: string;
  }) => {
    // checking if user is already in project
    const user = usersArray.find((item) => item.email === data.email.trim());
    if (user) {
      setError('email', {
        type: 'manual',
        message: 'Користувач вже є у проекті',
      });
      return;
    }
    dispatch(addUserToProject({ email: data.email.trim(), slug }))
      .unwrap()
      .then(() => {
        setValue('email', '');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeRole = (
    userId: number,
    role: string,
    email: string
  ): void => {
    dispatch(changeUserRole({ userId, role, slug, email }));
  };

  const handleDelete = (id: number, email: string): void => {
    dispatch(deleteUserFromProject({ userId: id, slug, email }));
  };

  const sortedUsers = useMemo(
    () =>
      usersArray.slice().sort((a: any) => {
        if (a.id === userInfo.id) {
          return -1;
        } else {
          return 1;
        }
      }),
    [JSON.stringify(usersArray)]
  );

  const customStyles = {
    menuPortal: (provided: any) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  return (
    <div className="modal__participants">
      <form className="form form--row" onSubmit={handleSubmit(onSubmit)}>
        <fieldset
          className="form__fieldset form__fieldset--margin"
          style={{ marginRight: '16px' }}
        >
          <label htmlFor="email">
            <Input
              id="email"
              placeholder="Введіть e-mail користувача"
              style={{ borderColor: errors.email?.message && '#FF0000' }}
              {...register('email', {
                required: true,
                pattern: emailValidationString,
              })}
              onChange={(val) => {
                const { value } = val.target;
                if (errors.email && value !== getValues('email')) {
                  clearErrors('email');
                }
              }}
            />
            {errors.email?.type === 'pattern' && (
              <span className="form__error">Невірний формат e-mail</span>
            )}
            {errors.email?.type === 'required' && (
              <span className="form__error">Введіть e-mail</span>
            )}
            {errors.email?.type === 'manual' && (
              <span className="form__error">{errors.email.message}</span>
            )}
          </label>
        </fieldset>

        <Button
          type="submit"
          view="primary"
          className="form__btn form__btn--margin"
          style={{ width: '160px', flexShrink: 0, alignSelf: 'flex-start' }}
        >
          Додати користувача
        </Button>
      </form>

      <div
        className={cx('list', ' participants', {
          [`participants--${theme}`]: theme,
        })}
      >
        {sortedUsers?.map(
          (user: TUserData, i): JSX.Element => (
            <React.Fragment key={user.id}>
              <div className="participants__text">
                <User
                  user={user}
                  isOnline={onlineUsers.find(
                    (item: any) => item.id === user.id
                  )}
                  size="md"
                  backgroundColor={
                    user.statusId === 1
                      ? PROJECT_ROLES_COLORS[user.roleId]
                      : '#C1C1C1'
                  }
                />
                <div className="participants__users-info">
                  <p className="participants__name">
                    {user.firstName
                      ? `${user.firstName} ${user.lastName}`
                      : 'Запрошений користувач'}
                  </p>
                  <p className="participants__name participants__name--custom">
                    {user.email}
                  </p>
                </div>
              </div>
              {console.log(roleList[user.roleId - 4].name) as any}
              {userInfo.id !== user.id &&
              userInfo.roleId === PROJECT_ROLES.productOwner ? (
                <label htmlFor="role">
                  <CustomSelect
                    styles={customStyles}
                    onChange={({ value, email }: any) =>
                      handleChangeRole(user.id, value, email)
                    }
                    options={roleList.map((info: any) => ({
                      value: info.role,
                      label: info.name,
                      email: user.email,
                    }))}
                    defaultValue={{
                      value: roleList[user.roleId - 4].role,
                      label: roleList[user.roleId - 4].name,
                    }}
                    // menuIsOpen={true}
                    placeholder={roleList[user.roleId - 4].name}
                    className="participants__select"
                    menuPortalTarget={document.body!}
                  />
                </label>
              ) : (
                <p
                  className="participants__name form__item"
                  style={{ marginRight: '20px' }}
                >
                  {user.roleId === PROJECT_ROLES.productOwner
                    ? roleList[0].name
                    : user.roleId === PROJECT_ROLES.productModerator
                    ? roleList[1].name
                    : roleList[2].name}
                </p>
              )}
              {userInfo.id !== user.id &&
              userInfo.roleId === PROJECT_ROLES.productOwner ? (
                <Button
                  view="warning"
                  type="button"
                  className={cx('task__delete-btn', 'participants__user-btn', {
                    [`task__delete-btn--${theme}`]: theme,
                  })}
                  onClick={() => handleDelete(user.id, user.email)}
                >
                  <span className="ci ci-trash_full task__delete-icon" />
                </Button>
              ) : (
                <div />
              )}
            </React.Fragment>
          )
        )}
      </div>
    </div>
  );
};

export default AddParticipants;
